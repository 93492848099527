import React, { useState, useEffect } from 'react';

import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress'

import { WebAuth }  from 'auth0-js';

import AppSettings from './AppSettings';
import { useHistory } from 'react-router-dom';

var webAuth = new WebAuth({
  domain: AppSettings.GraphQLAuthDomain,
  clientID: AppSettings.GraphQLAuthClientID,
  redirectUri: window.location.protocol + '//' + window.location.host + '/auth_callback',
  audience: AppSettings.GraphQLAuthAudience,
  responseType: 'token id_token'
});

const setSession = (authResult) => {
  const expiresAt = JSON.stringify(
    authResult.expiresIn * 1000 + new Date().getTime()
  )
  localStorage.setItem('sqb_auth0_access_token', authResult.accessToken)
  localStorage.setItem('sqb_auth0_id_token', authResult.idToken)
  localStorage.setItem('sqb_auth0_expires_at', expiresAt)
}

const TemplateAuth = props => {
  const [loggedIn, setLoggedIn] = useState(false);
  const history = useHistory();

  const handleAuthSuccess = (authResult) => {
    // Set session parameters, including the datetime that the access token will expire at
    setSession(authResult);
    setLoggedIn(true);
  }

  useEffect(() => {
    if (loggedIn) {
      history.push('/portfolio')
    } else {
      webAuth.parseHash({ hash: window.location.hash }, (err, authResult) => {
        // console.log("err,authResult ==> ", err, authResult)
        if (err && (err.error === "login_required")) {
            webAuth.authorize({prompt: "login"});
        } else {  
          if (authResult && authResult.accessToken && authResult.idToken) {
            handleAuthSuccess(authResult);
          } else {
            console.warn('Login unsuccessfull - trying refresh')
            webAuth.checkSession({}, (newErr, newAuthresult) => {
              if (newErr) { console.log(newErr) }
              if (newAuthresult && newAuthresult.accessToken && newAuthresult.idToken) {
                handleAuthSuccess(newAuthresult);
              } else {
                console.warn('Login unsuccessful - giving up')
                history.push('/');
              }
            })
          }
        }
      });
    }
  }, [loggedIn, history])

  return (
    <div style={{minHeight: '100vh',
                backgroundImage: 'url(/static/images/bg_dk-sq.jpg)',
                fontFamily: 'montserrat',
                zIndex: 1,
                backgroundSize: 'cover',
                overflow: 'hidden'}}>
      <main style={{flexGrow: 1, paddingTop: 100, textAlign: 'center'}}>
        <div><img alt="DeltaQ" src="/static/images/DeltaQ_color.png" width="50%" /></div>
        <Typography variant="h5" style={{color: '#FFFFFF'}} noWrap>
          &nbsp;
        </Typography>
        <Typography variant="h5" style={{color: '#FFFFFF'}} noWrap>
          &nbsp;
        </Typography>
        <Typography variant="h5" style={{color: '#FFFFFF'}} noWrap>
          &nbsp;
        </Typography>
        <Typography variant="h5" style={{color: '#FFFFFF'}} noWrap>
          &nbsp;
        </Typography>
        <Typography variant="h5" style={{color: '#FFFFFF'}} noWrap>
          &nbsp;
        </Typography>
        <CircularProgress size={20} color="secondary" />
      </main>
    </div>
  );
}

export default TemplateAuth;
