import React from 'react'

import ProjectOverviewBuildingStates from '../components/ProjectOverviewBuildingStates'
import ProjectOverviewFeatures from '../components/ProjectOverviewFeatures'
import ProjectOverviewDeployments from '../components/ProjectOverviewDeployments'

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

class ProjectOverview extends React.Component {
  render() {
    return (
      <div>
        <div>
          <ExpansionPanel TransitionProps={{ unmountOnExit: true }}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Building States</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <ProjectOverviewBuildingStates />
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel TransitionProps={{ unmountOnExit: true }}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Feature Status</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <ProjectOverviewFeatures />
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel TransitionProps={{ unmountOnExit: true }}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Deployment Status</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <ProjectOverviewDeployments />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
        <div><br/></div>
      </div>
    )
  }
}

export default ProjectOverview;
