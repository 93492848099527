import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';

import gql from 'graphql-tag';

import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar';
import Switch from '@material-ui/core/Switch';

const getBackgoundColor = (level, client) => (
  level==="ok"
  ? client.colorStateOk
  : level==="info"
  ? client.colorStateOk
  : level==="warning"
  ? client.colorStateWarning
  : level==="error"
  ? client.colorStateError
  : client.colorStateInactive
)

const State = props => {
  const projectID = props.match.params.projectID

  const { data, loading, error } = useQuery(getCurrentUser, { variables: { projectID }})
  const [ validateBuildingStateMutation ] = useMutation(validateBuildingState)
  const [ inValidateBuildingStateMutation ] = useMutation(inValidateBuildingState)
  const refetchQuery = { query: getCurrentUser, variables: { projectID }}

  const handleBuildingStateValidation = (building_state_id) => {
    validateBuildingStateMutation({variables: { buildingStateId: building_state_id }, refetchQueries: [refetchQuery]})
  };

  const handleBuildingStateInValidation = (building_state_id) => {
    inValidateBuildingStateMutation({variables: { buildingStateId: building_state_id }, refetchQueries: [refetchQuery]})
  };

  return (
    <TableContainer component={Paper}><Table>
        {error ? (
            <TableBody><TableRow><TableCell>
                <Typography gutterBottom>Error fetching building state!</Typography>
            </TableCell></TableRow></TableBody>
        ) : loading ? (
            <TableBody><TableRow><TableCell>
                <CircularProgress size={50} color="secondary" />
            </TableCell></TableRow></TableBody>
        ) : !data.me ? (
            <TableBody><TableRow><TableCell>
                <Typography gutterBottom>Loading building state...</Typography>
            </TableCell></TableRow></TableBody>
        ) : (
            <>
            <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Validated</TableCell>
                  <TableCell>DateTime</TableCell>
                  <TableCell>Message</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {JSON.parse(JSON.stringify(data.me.client.buildings[0].buildingStates))
                    .sort((a, b) =>
                        (a.category.localeCompare(b.category) !== 0)
                        ? a.category.localeCompare(b.category)
                        : a.title.localeCompare(b.title)
                    )
                    .map((buildingState, _index) => {
                        return (
                          <TableRow key={buildingState.id + "_row"}>
                            <TableCell key={buildingState.id + "_level"}>
                              <Avatar aria-label={buildingState.level} alt={buildingState.level} style={{backgroundColor:getBackgoundColor(buildingState.level, data.me.client)}}>
                                &nbsp;
                              </Avatar>
                            </TableCell>
                            <TableCell key={buildingState.id + "_category"}>
                              <Typography>{buildingState.category}</Typography>
                            </TableCell>
                            <TableCell key={buildingState.id + "_title"}>
                              <Typography>{buildingState.title}</Typography>
                            </TableCell>
                            <TableCell key={buildingState.id + "_validated"}>
                              <Switch checked={buildingState.alertValidated} onChange={(_event, checked) => checked ? handleBuildingStateValidation(buildingState.id) : handleBuildingStateInValidation(buildingState.id)} />
                            </TableCell>
                            <TableCell key={buildingState.id + "_datetime"}>
                              <Typography>{buildingState.updatedAt}</Typography>
                            </TableCell>
                            <TableCell key={buildingState.id + "_message"}>
                              <Typography style={{"white-space": "pre-line"}}>{buildingState.message.replace(/ ; /gi, "\n")}</Typography>
                            </TableCell>
                          </TableRow>
                    )})
                }
            </TableBody>
            </>
        )}
    </Table></TableContainer>
  );
}

const getCurrentUser = gql`
query getCurrentUser ($projectID: String!) {
    me {
        id
        externalBuildingRoles (where: {building: {projectID: $projectID}}) {
          id
          role
          building {
            id
            projectID
            buildingStates {
              id
              category
              level
              title
              message
              payload
              alertValidated
              updatedAt
            }
          }
        }
        client {
            id
            colorStateOk
            colorStateWarning
            colorStateError
            colorStateInactive
            buildings (where: {projectID: $projectID}) {
                id
                projectID
                buildingStates {
                    id
                    category
                    level
                    title
                    message
                    payload
                    alertValidated
                    updatedAt
                }
            }
        }
    }
}`

const validateBuildingState = gql`
mutation validateBuildingState($buildingStateId: ID!) {
  updateBuildingState ( id: $buildingStateId alertValidated: true ) { id }
}`;

const inValidateBuildingState = gql`
mutation inValidateBuildingState($buildingStateId: ID!) {
  updateBuildingState ( id: $buildingStateId alertValidated: false ) { id }
}`;

export default State