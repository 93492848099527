import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import withStyles from '@material-ui/core/styles/withStyles';
import gql from 'graphql-tag';

import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Save from '@material-ui/icons/Save';

import moment from 'moment';

import ReactDataSheet from 'react-datasheet';
import 'react-datasheet/lib/react-datasheet.css';

import { XYPlot, XAxis, YAxis, HorizontalGridLines, VerticalBarSeries } from 'react-vis';
import 'react-vis/dist/style.css';

import { DropDownYearInvoice } from './DropDownYearInvoice'

import { CurrentUserContext } from '../contexts/CurrentUser';

const styles = theme => ({
  root: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
  }),
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 130,
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
});

const parsePaste = clipboardData => {
  return clipboardData
     .split(/\r\n|\n|\r/)
     .filter(row => row.length > 0)
     .map(row => row.split("\t"));
}

const _yTickFormatValue = (unit) => ((v, i, scale, tickTotal) => {
  return `${(scale.tickFormat(tickTotal, 'd')(v)).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1\u00a0')} ` + unit;
});

const BUILDINGMETRIC_METADATA = {
  "electricity": {
    name: "Electricity",
    consumption: {
      unit: "kWh",
      limits: [0, 100000000]
    },
    cost: {
      unit: "EUR",
      limits: [0, 10000000]
    },
    pv_injection: {
      unit: "kWh",
      limits: [0, 10000000]
    },
    pv_production: {
      unit: "kWh",
      limits: [0, 10000000]
    },
  },
  "gas": {
    name: "Gas",
    consumption: {
      unit: "kWh",
      limits: [0, 100000000]
    },
    cost: {
      unit: "EUR",
      limits: [0, 5000000]
    },
  },
  "heat": {
    name: "Heat",
    consumption: {
      unit: "kWh",
      limits: [0, 100000000]
    },
    cost: {
      unit: "EUR",
      limits: [0, 5000000]
    },
  },
  "cold": {
    name: "Cold",
    consumption: {
      unit: "kWh",
      limits: [0, 100000000]
    },
    cost: {
      unit: "EUR",
      limits: [0, 5000000]
    },
  },
  "water": {
    name: "Water",
    consumption: {
      unit: "m³",
      limits: [0, 10000]
    },
    cost: {
      unit: "EUR",
      limits: [0, 50000]
    },
  }
}

const getEmptyGrid = (buildingMetricKey) => {

  let grid
  if (buildingMetricKey==='electricity')
  {
    grid = [
      [
        {readOnly: true, width: 100, value: ''},
        {readOnly: true, width: 110, value: 'StartDate'},
        {readOnly: true, width: 110, value: 'EndDate'},
        {readOnly: true, width: 110, value: 'Consumption'},
        {readOnly: true, width: 110, value: 'Cost'},
        {readOnly: true, width: 110, value: 'PV injection'},
        {readOnly: true, width: 110, value: 'PV production'},
      ],
      [
        {readOnly: true, width: 100, value: ''},
        {readOnly: true, width: 110, value: 'yyyy-mm-dd'},
        {readOnly: true, width: 110, value: 'yyyy-mm-dd'},
        {readOnly: true, width: 110, value: '[' + BUILDINGMETRIC_METADATA[buildingMetricKey]['consumption']['unit'] + ']'},
        {readOnly: true, width: 110, value: '[' + BUILDINGMETRIC_METADATA[buildingMetricKey]['cost']['unit'] + ']'},
        {readOnly: true, width: 110, value: '[' + BUILDINGMETRIC_METADATA[buildingMetricKey]['pv_injection']['unit'] + ']'},
        {readOnly: true, width: 110, value: '[' + BUILDINGMETRIC_METADATA[buildingMetricKey]['pv_production']['unit'] + ']'},
      ],
      [{readOnly: true, value: 'Invoice 01'}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}],
      [{readOnly: true, value: 'Invoice 02'}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}],
      [{readOnly: true, value: 'Invoice 03'}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}],
      [{readOnly: true, value: 'Invoice 04'}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}],
      [{readOnly: true, value: 'Invoice 05'}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}],
      [{readOnly: true, value: 'Invoice 06'}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}],
      [{readOnly: true, value: 'Invoice 07'}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}],
      [{readOnly: true, value: 'Invoice 08'}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}],
      [{readOnly: true, value: 'Invoice 09'}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}],
      [{readOnly: true, value: 'Invoice 10'}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}],
      [{readOnly: true, value: 'Invoice 11'}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}],
      [{readOnly: true, value: 'Invoice 12'}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}],
      [{readOnly: true, value: 'Invoice 13'}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}],
      [{readOnly: true, value: 'Invoice 14'}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}],
    ]
  }
  else
  {grid = [
    [
      {readOnly: true, width: 100, value: ''},
      {readOnly: true, width: 110, value: 'StartDate'},
      {readOnly: true, width: 110, value: 'EndDate'},
      {readOnly: true, width: 110, value: 'Consumption'},
      {readOnly: true, width: 110, value: 'Cost'},
    ],
    [
      {readOnly: true, width: 100, value: ''},
      {readOnly: true, width: 110, value: 'yyyy-mm-dd'},
      {readOnly: true, width: 110, value: 'yyyy-mm-dd'},
      {readOnly: true, width: 110, value: '[' + BUILDINGMETRIC_METADATA[buildingMetricKey]['consumption']['unit'] + ']'},
      {readOnly: true, width: 110, value: '[' + BUILDINGMETRIC_METADATA[buildingMetricKey]['cost']['unit'] + ']'},
    ],
    [{readOnly: true, value: 'Invoice 01'}, {value: ''}, {value: ''}, {value: ''}, {value: ''}],
    [{readOnly: true, value: 'Invoice 02'}, {value: ''}, {value: ''}, {value: ''}, {value: ''}],
    [{readOnly: true, value: 'Invoice 03'}, {value: ''}, {value: ''}, {value: ''}, {value: ''}],
    [{readOnly: true, value: 'Invoice 04'}, {value: ''}, {value: ''}, {value: ''}, {value: ''}],
    [{readOnly: true, value: 'Invoice 05'}, {value: ''}, {value: ''}, {value: ''}, {value: ''}],
    [{readOnly: true, value: 'Invoice 06'}, {value: ''}, {value: ''}, {value: ''}, {value: ''}],
    [{readOnly: true, value: 'Invoice 07'}, {value: ''}, {value: ''}, {value: ''}, {value: ''}],
    [{readOnly: true, value: 'Invoice 08'}, {value: ''}, {value: ''}, {value: ''}, {value: ''}],
    [{readOnly: true, value: 'Invoice 09'}, {value: ''}, {value: ''}, {value: ''}, {value: ''}],
    [{readOnly: true, value: 'Invoice 10'}, {value: ''}, {value: ''}, {value: ''}, {value: ''}],
    [{readOnly: true, value: 'Invoice 11'}, {value: ''}, {value: ''}, {value: ''}, {value: ''}],
    [{readOnly: true, value: 'Invoice 12'}, {value: ''}, {value: ''}, {value: ''}, {value: ''}],
    [{readOnly: true, value: 'Invoice 13'}, {value: ''}, {value: ''}, {value: ''}, {value: ''}],
    [{readOnly: true, value: 'Invoice 14'}, {value: ''}, {value: ''}, {value: ''}, {value: ''}],
  ]}

  grid.forEach(row => {
    row.forEach(col => {
      col.isValid = true;
    })
  })
  return grid;
}

const getGridValidity = (grid) => {
  let isValid = true;
  grid.forEach(row => {
    row.forEach(cell => {
      if (cell.readOnly) return;
      if (!cell.isValid) {
        isValid = false;
      }
    })
  })
  return isValid;
}

const getGridDataFromMetricPayloads = (metricPayloads, buildingMetricKey) => {
  const consumptionPayload = metricPayloads.get("invoice_consumption_" + buildingMetricKey);
  const costPayload = metricPayloads.get("invoice_cost_" + buildingMetricKey);
  if (buildingMetricKey === 'electricity')
    {
    const pvInjectionPayload = metricPayloads.get("invoice_pv_injection")
    const pvProductionPayload = metricPayloads.get("invoice_pv_production")
    return [...new Set([...Object.keys(consumptionPayload), ...Object.keys(costPayload), ...Object.keys(pvInjectionPayload), ...Object.keys(pvProductionPayload)])]
    .sort()
    .map(k => [
      {width: 100, isValid: true, value: k.split('|')[0]},
      {width: 100, isValid: true, value: k.split('|')[1]},
      {width: 100, isValid: true, value: (consumptionPayload[k] || consumptionPayload[k] === 0) ? consumptionPayload[k] : ""},
      {width: 100, isValid: true, value: (costPayload[k] || costPayload[k] === 0) ? costPayload[k] : ""},
      {width: 100, isValid: true, value: (pvInjectionPayload[k] || pvInjectionPayload[k] === 0) ? pvInjectionPayload[k] : ""},
      {width: 100, isValid: true, value: (pvProductionPayload[k] || pvProductionPayload[k] === 0) ? pvProductionPayload[k] : ""},
    ])
  }
else
{
  return [...new Set([...Object.keys(consumptionPayload), ...Object.keys(costPayload)])]
    .sort()
    .map(k => [
      {width: 100, isValid: true, value: k.split('|')[0]},
      {width: 100, isValid: true, value: k.split('|')[1]},
      {width: 100, isValid: true, value: (consumptionPayload[k] || consumptionPayload[k] === 0) ? consumptionPayload[k] : ""},
      {width: 100, isValid: true, value: (costPayload[k] || costPayload[k] === 0) ? costPayload[k] : ""},
    ])
}
}

const getGridContentFromGridData = (gridData, buildingMetricKey, year) => {
  var gridContent = getEmptyGrid(buildingMetricKey);
  gridData
  .filter((r, i) => parseInt(r[0].value.split('-')[0], 10) === year || parseInt(r[1].value.split('-')[0], 10) === year)
  .forEach((invoice, i) => {
    invoice.forEach((field, f) => {
      gridContent[i+2][f+1] = field;
    })
  })
  return gridContent
}

const getDataWindow = (gridData, year) => {
  let dataWindow = gridData.filter((r, i) => parseInt(r[0].value.split('-')[0], 10) === year || parseInt(r[1].value.split('-')[0], 10) === year)
  while (dataWindow.length < 14) {
    if (gridData.length>0 && gridData.map(d => d.length).every((x) => x === 6))
    {
    dataWindow.push([
      {width: 100, isValid: true, value: ""},
      {width: 100, isValid: true, value: ""},
      {width: 100, isValid: true, value: ""},
      {width: 100, isValid: true, value: ""},
      {width: 100, isValid: true, value: ""},
      {width: 100, isValid: true, value: ""},
    ])
  }
  else
  {
    dataWindow.push([
      {width: 100, isValid: true, value: ""},
      {width: 100, isValid: true, value: ""},
      {width: 100, isValid: true, value: ""},
      {width: 100, isValid: true, value: ""},
    ])
  }
  }
  return dataWindow
}

const DataInputInvoices = props => {
  const { classes } = props;

  const [selectedBuildingMetricKey, setSelectedBuildingMetricKey] = useState("electricity");
  const [selectedYear, setSelectedYear] = useState((new Date()).getFullYear());

  const [metricIds, setMetricIds] = useState({});
  const [metricPayloads, setMetricPayloads] = useState({});
  const [gridData, setGridData] = useState([]);
  const [gridContent, setGridContent] = useState(getEmptyGrid(selectedBuildingMetricKey));

  const { data, loading, error, refetch } = useQuery(getInvoiceBuildingMetrics, {
    variables: { projectID: props.match.params.projectID },
    onCompleted: data => {
      if (data.building?.buildingMetrics && data.building?.buildingMetrics?.length!==0){
        setMetricIds(new Map(data.building.buildingMetrics.map(bm => [bm.buildingMetricTemplate.name, bm.id])))
        setMetricPayloads(new Map(data.building.buildingMetrics.map(bm => [bm.buildingMetricTemplate.name, bm.payload])))
      }
    }
  })

  useEffect(() => {
    if (metricPayloads && metricPayloads.size && metricPayloads.size!==0) {
      setGridData(getGridDataFromMetricPayloads(metricPayloads, selectedBuildingMetricKey))
    }
  }, [metricPayloads, selectedBuildingMetricKey]);

  useEffect(() => {
    setGridContent(getGridContentFromGridData(gridData, selectedBuildingMetricKey, selectedYear))
  }, [gridData, selectedBuildingMetricKey, selectedYear]);

  const [ updateBuildingMetricPayloadMutation ] = useMutation(updateBuildingMetricPayload)

  if (loading) return (<Paper style={{ padding: 25 }}><CircularProgress size={50} color="secondary" /></Paper>)
  if (error) return (<Paper style={{ padding: 25 }}><Typography gutterBottom>Error fetching invoice data!</Typography></Paper>)
  if (!data.building || data.building.buildingMetrics.length===0 || !gridData) return (<Paper style={{ padding: 25 }}><Typography gutterBottom>No data available</Typography></Paper>)

  const handleCellSelect = ({ start, end }) => {
    let visibleGridData = getDataWindow(gridData, selectedYear);
    if (start.i===end.i && start.j===end.j
      && start.i > 2 && (start.j === 1 || start.j === 2)
      && visibleGridData[start.i-2][start.j-1]["value"] === "") {
        if (start.j === 1 && visibleGridData[start.i-3][start.j]["isValid"] && visibleGridData[start.i-3][start.j]["value"] !== "") {
          if (gridData.length>0 && gridData.map(d => d.length).every((x) => x === 6))
          {
          gridData.push([
            {width: 100, isValid: true, value: moment(visibleGridData[start.i-3][start.j]["value"]).add(1, 'd').format('YYYY-MM-DD')},
            {width: 100, isValid: true, value: ""},
            {width: 100, isValid: true, value: ""},
            {width: 100, isValid: true, value: ""},
            {width: 100, isValid: true, value: ""},
            {width: 100, isValid: true, value: ""},
          ])
        }
        else
        {
          gridData.push([
            {width: 100, isValid: true, value: moment(visibleGridData[start.i-3][start.j]["value"]).add(1, 'd').format('YYYY-MM-DD')},
            {width: 100, isValid: true, value: ""},
            {width: 100, isValid: true, value: ""},
            {width: 100, isValid: true, value: ""},
          ])
        }
          const newGridDate = gridData.sort((a,b) => a[0].value.localeCompare(b[0].value))
          setGridData(newGridDate);
          setGridContent(getGridContentFromGridData(newGridDate, selectedBuildingMetricKey, selectedYear));
        } else if (start.j === 2 && visibleGridData[start.i-2][start.j-2]["isValid"] && visibleGridData[start.i-2][start.j-2]["value"] !== "") {
          let cell = gridData.find(d => d[0]["value"]===visibleGridData[start.i-2][start.j-2]["value"] && d[1]["value"]==="")
          cell[1]["value"] = moment(visibleGridData[start.i-2][start.j-2]["value"]).add(1, 'M').add(-1, 'd').format('YYYY-MM-DD')
          cell[1]["isValid"] = true
          setGridData(gridData);
          setGridContent(getGridContentFromGridData(gridData, selectedBuildingMetricKey, selectedYear));
        }
    }
  }

  const handleCellChange = (changes) => {
    const invisibleGridData = gridData.filter((r, i) => parseInt(r[0].value.split('-')[0], 10) !== selectedYear && parseInt(r[1].value.split('-')[0], 10) !== selectedYear);
    let visibleGridData = getDataWindow(gridData, selectedYear);
    changes.forEach(({cell, row, col, value}) => {
      if (col===1 || col===2) {
        if (value==="") {
          visibleGridData[row-2][col-1]["value"] = "";
          visibleGridData[row-2][col-1]["isValid"] = true;
        } else if (moment(value).isValid()) {
          visibleGridData[row-2][col-1]["value"] = moment(value).format('YYYY-MM-DD');
          visibleGridData[row-2][col-1]["isValid"] = true;
        } else {
          visibleGridData[row-2][col-1]["value"] = value;
          visibleGridData[row-2][col-1]["isValid"] = false;
        }
      } else {
        const new_value = parseFloat(value);
        if (Number.isNaN(new_value)) {
          visibleGridData[row-2][col-1]["value"] = "";
          visibleGridData[row-2][col-1]["isValid"] = true;
        } else {
          visibleGridData[row-2][col-1]["value"] = new_value;
          const [min, max] = BUILDINGMETRIC_METADATA[selectedBuildingMetricKey][col===3 ? "consumption" : "cost"]['limits'];
          visibleGridData[row-2][col-1]["isValid"] = (new_value <= max && new_value >= min);
        }
      }
    });

    setGridData(
      visibleGridData
      .filter(d => d[0].value.length === 10)
      .concat(invisibleGridData)
      .sort((a,b) => a[0].value.localeCompare(b[0].value))
    );
  }
  
  const saveBuildingMetricValues = async () => {
    const consumptionData = gridData.filter(d => d[0].value.length === 10 && d[2].value !== "" && d[2].isValid)
    if (consumptionData.length > 0) {
      const consumptionId = metricIds.get("invoice_consumption_" + selectedBuildingMetricKey);
      const consumptionPayload = consumptionData.reduce((obj, item) => {
        obj[item[0].value + "|" + item[1].value] = item[2].value
        return obj
      }, {})
      await updateBuildingMetricPayloadMutation({
        variables: {
          id: consumptionId,
          payload: consumptionPayload,
        },
      });
    }

    const costData = gridData.filter(d => d[0].value.length === 10 && d[3].value !== "" && d[3].isValid)
    if (costData.length > 0) {
      const costId = metricIds.get("invoice_cost_" + selectedBuildingMetricKey);
      const costPayload = costData.reduce((obj, item) => {
        obj[item[0].value + "|" + item[1].value] = item[3].value
        return obj
      }, {})
      await updateBuildingMetricPayloadMutation({
        variables: {
          id: costId,
          payload: costPayload,
        },
      });
    }

    const pvInjectionData = gridData.filter(d => d[0].value.length === 10 && d[4] && d[4].value !== "" && d[4].isValid)
    if (pvInjectionData.length > 0) {
      const pvInjectionId = metricIds.get("invoice_pv_injection");
      const pvInjectionPayload = pvInjectionData.reduce((obj, item) => {
        obj[item[0].value + "|" + item[1].value] = item[4].value
        return obj
      }, {})
      await updateBuildingMetricPayloadMutation({
        variables: {
          id: pvInjectionId,
          payload: pvInjectionPayload,
        },
      });
    }

    const pvProductionData = gridData.filter(d => d[0].value.length === 10 && d[5] && d[5].value !== "" && d[5].isValid)
    if (pvProductionData.length > 0) {
      const pvProductionId = metricIds.get("invoice_pv_production");
      const pvProductionPayload = pvProductionData.reduce((obj, item) => {
        obj[item[0].value + "|" + item[1].value] = item[5].value
        return obj
      }, {})
      await updateBuildingMetricPayloadMutation({
        variables: {
          id: pvProductionId,
          payload: pvProductionPayload,
        },
      });
    }

    await refetch()
  }

 return (
    <CurrentUserContext.Consumer>{ me => { return (
    <Paper className={classes.root} elevation={0}>
        <Grid container alignItems="center" spacing={4}>
          <Grid item xs={6} sm={4} md={3} lg={2} xl={1}>
            <TextField select
              value={selectedBuildingMetricKey}
              onChange={(event) => setSelectedBuildingMetricKey(event.target.value)}
              className={classes.textField}
              margin="normal"
            >
                <MenuItem key="electricity" value="electricity">Electricity</MenuItem>
                <MenuItem key="gas" value="gas">Gas</MenuItem>
                <MenuItem key="water" value="water">Water</MenuItem>
                <MenuItem key="heat" value="heat">Heat</MenuItem>
                <MenuItem key="cold" value="cold">Cold</MenuItem>

            </TextField>
          </Grid>
          <Grid item xs={6} sm={8} md={9} lg={10} xl={11}>
            <DropDownYearInvoice
              building={data.building}
              value={selectedYear}
              onChange={(event) => setSelectedYear(event.target.value)}
              className={classes.textField}
              margin="normal"
              >
            </DropDownYearInvoice>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <ReactDataSheet
              data={gridContent}
              valueRenderer={(cell) => cell.value}
              onCellsChanged={handleCellChange}
              onSelect={handleCellSelect}
              parsePaste={parsePaste}
              valueViewer={({cell, value}) => {
                if (!cell.isValid) {
                  return (
                    <span style={{backgroundColor: "red", color: "white"}}>
                      {cell.value}  
                    </span>
                  )
                }
                return cell.value;
              }}                  
            />
            <Typography variant="subtitle1">
              Fill out invoice amounts (excluding VAT, including other taxes and fees).<br/>
              Provide invoice period dates to improve accuracy on savings computations.<br/>
              Add invoices in any order, they will be sorted by date on page load.<br/>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Grid container alignItems="center" spacing={0}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <XYPlot width={400} height={200} margin={{top: 10, right: 10, left: 150, bottom: 90}}>
                  <HorizontalGridLines style={{stroke: '#999999'}} />
                  <XAxis tickValues={getDataWindow(gridData, selectedYear).map((r, c) => c)} tickFormat={(v, i, scale, tickTotal) => getDataWindow(gridData, selectedYear).map(r => r[0]["value"])[i]} tickLabelAngle={-60} height={80} style={{ text: {fontSize: '12px'} }} />
                  <YAxis tickFormat={_yTickFormatValue(BUILDINGMETRIC_METADATA[selectedBuildingMetricKey]['consumption']['unit'])} width={140} style={{ text: {fontSize: '12px'} }} />
                  <VerticalBarSeries
                      data={getDataWindow(gridData, selectedYear).map((r, c) => ({x: c, y: r[2]["value"]}))}
                      colorType={"literal"}
                      color={me.client.colorGasSavings}
                      fill={me.client.colorGasSavings}
                      stroke={me.client.colorGasConsumption}
                      barWidth={0.6}
                      getNull={(d) => d.y !== null && d.y !== undefined}
                  />
                </XYPlot>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <XYPlot width={400} height={200} margin={{top: 10, right: 10, left: 150, bottom: 90}}>
                  <HorizontalGridLines style={{stroke: '#999999'}} />
                  <XAxis tickValues={getDataWindow(gridData, selectedYear).map((r, c) => c)} tickFormat={(v, i, scale, tickTotal) => getDataWindow(gridData, selectedYear).map(r => r[0]["value"])[i]} tickLabelAngle={-60} height={80} style={{ text: {fontSize: '12px'} }} />
                  <YAxis tickFormat={_yTickFormatValue(BUILDINGMETRIC_METADATA[selectedBuildingMetricKey]['cost']['unit'])} width={140} style={{ text: {fontSize: '12px'} }} />
                  <VerticalBarSeries
                      data={getDataWindow(gridData, selectedYear).map((r, c) => ({x: c, y: r[3]["value"]}))}
                      colorType={"literal"}
                      color={me.client.colorStateError}
                      fill={me.client.colorStateError}
                      stroke={me.client.colorStateError}
                      barWidth={0.6}
                      getNull={(d) => d.y !== null && d.y !== undefined}
                  />
                </XYPlot>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
              <Button variant="contained" onClick={saveBuildingMetricValues} disabled={!getGridValidity(gridData)}>
                <Save className={classes.leftIcon} />
                Save Invoices
              </Button>
          </Grid>

          {getGridValidity(gridData) ? null : (
            <Grid item xs={12}>
              The invoice data can not be saved! Please ensure the following limits are respected:
              <ul>
                {Object.keys(BUILDINGMETRIC_METADATA).map((inputRuleKey, i) => [
                  <li key={i}><strong>{BUILDINGMETRIC_METADATA[inputRuleKey]["name"]} Consumption</strong> must be &gt; {BUILDINGMETRIC_METADATA[inputRuleKey]["consumption"]["limits"][0]} and &lt; {BUILDINGMETRIC_METADATA[inputRuleKey]["consumption"]["limits"][1]}</li>
                  ,
                  <li key={i}><strong>{BUILDINGMETRIC_METADATA[inputRuleKey]["name"]} Cost</strong> must be &gt; {BUILDINGMETRIC_METADATA[inputRuleKey]["cost"]["limits"][0]} and &lt; {BUILDINGMETRIC_METADATA[inputRuleKey]["cost"]["limits"][1]}</li>
                ])}
              </ul>
            </Grid>
          )}
        </Grid>
    </Paper>
    )}}
    </CurrentUserContext.Consumer>
  );
}

const getInvoiceBuildingMetrics = gql`
query getInvoiceBuildingMetrics($projectID: String!) {
  building(where: {projectID: $projectID}) {
    id
    activationDate
    activationDateDashboard
    buildingMetrics(
      where:{ AND: [
        {
          buildingMetricTemplate:{ name_in: ["invoice_consumption_electricity", "invoice_cost_electricity", "invoice_consumption_gas", "invoice_cost_gas", "invoice_consumption_water", "invoice_cost_water", 
          "invoice_pv_production", "invoice_pv_injection", "invoice_consumption_heat", "invoice_consumption_cold", "invoice_cost_heat", "invoice_cost_cold"]}
        },{
          buildingMetricTags_some: { key: "creator" value: "userinput" },
        }
      ]}) {
          id
          payload
          buildingMetricTags {
            id
            key
            value
          }
          buildingMetricTemplate {
              id
              name
              displayName
          }
      }
  }
}`

const updateBuildingMetricPayload = gql`
mutation updateBuildingMetricPayload($id: ID!, $payload: Json!) {
  updateBuildingMetricPayload(
    id: $id
    payload: $payload
  ) { id }
}`;

export default withStyles(styles)(DataInputInvoices);
