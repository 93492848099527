import moment from 'moment';
import React from 'react'
import DropDownYear from './DropDownYear';

export const availableYearsForCalendar = ({activationDate, activationDateDashboard}) => {
  const currentYear = (new Date()).getFullYear();
  const getYear = (dateString) => moment(dateString, 'YYYY-MM-DD', true).year();
  let yearOfActivation = (new Date()).getFullYear();
  
  if (!activationDate && !activationDateDashboard) {
    yearOfActivation = currentYear;
  } else if (activationDateDashboard) {
    yearOfActivation = getYear(activationDateDashboard);
  } else if (activationDate) {
    yearOfActivation = getYear(activationDate);
  } 
  const lastYear = Math.max(currentYear, yearOfActivation);
  const firstYear = yearOfActivation;
  let years = [];
  for (let y = firstYear; y < lastYear + 2; y++) {
    years.push(y);
  }
  return years;
}

/**
 * this is the "Year" dropdown seen on the invoice input page. 
 */
export const DropDownYearCalendar = props => {
  const {building} = props;
  const years = availableYearsForCalendar(building)
  return (
    <DropDownYear years={years} {...props} />
  )
};

export default DropDownYearCalendar;