import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag'

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';

import MuiForm from 'rjsf-material-ui';




const ConfigCalendar = props => {
  const projectID = props.projectID
  // const projectID = props.match.params.projectID
  const dataProfileContexts = useQuery(getProfileContexts, { variables:   {projectID} })

  const [ updateProfileContextMutation ] = useMutation(updateProfileContext)
  const [ updateProfileDayTypeMutation ] = useMutation(updateProfileDayType)
  const [ updateProfileDayScheduleMutation ] = useMutation(updateProfileDaySchedule)

  if (dataProfileContexts.loading) return (<Grid><CircularProgress size={50} color="secondary" /></Grid>)
  if (dataProfileContexts.error) return (<Grid><Typography gutterBottom>Error fetching ProfileContexts data!</Typography></Grid>)
  if (!dataProfileContexts.data.profileContexts) return (<Typography gutterBottom>No ProfileContexts Found!</Typography>)
  
  const handleProfileContextSubmit = ({formData}, id) => {
    updateProfileContextMutation({
      variables: {
        id: id,
        projectID: projectID,
        scalingType: formData.scalingType,
        scalingParameters: JSON.stringify(formData.scalingParameters)
      },
      refetchQueries: [{ query: getProfileContexts ,
        variables:{projectID:projectID}}],
    }).then(response => {
      // handle the response
    });
  }

  const handleProfileDayTypeSubmit = ({formData}, id) => {
    updateProfileDayTypeMutation({
      variables: {
        id: id,
        projectID: projectID,
        priority: formData.priority,
        weekDays: formData.weekDays,
      },
      refetchQueries: [{ query: getProfileContexts ,
        variables:{projectID:projectID}}],
    }).then(response => {
      // handle the response
    });
  }

  const handleProfileDayScheduleSubmit = ({formData}, id) => {
    updateProfileDayScheduleMutation({
      variables: {
        id: id,
        projectID: projectID,
        payload: JSON.stringify(formData.payload),
      },
      refetchQueries: [{ query: getProfileContexts ,
        variables:{projectID:projectID}}],
    }).then(response => {
      // handle the response
    });
  }
  return (
    
    <ExpansionPanel key={"profileContext"} TransitionProps={{ unmountOnExit: true }} defaultExpanded={false}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6" gutterBottom>{"Profile Context"}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid style={{ width: "100%" }}>
          {dataProfileContexts.data.profileContexts.map(profileContext => {
            return (<ExpansionPanel key={profileContext.displayName} defaultExpanded={false}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography key="bn" variant="h6" gutterBottom>{profileContext.displayName}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid style={{ width: "100%" }}>
                <MuiForm schema={profileContextSchema} formData={profileContext} onSubmit={(formData)=>handleProfileContextSubmit(formData, profileContext.id)}>
                              <Button style={{ display: 'inline-block' }} variant="contained" color="primary" type="submit" >
                                Update ProfileContext</Button>
                </MuiForm>

                <div class="header">
                  <h1>Calendar contents</h1>
                </div>
                  {profileContext.profileContextSchedules.map(profileContextSchedule => {
                    if (profileContextSchedule.profileDayType && profileContextSchedule.profileDaySchedule)
                    {
                    return (<ExpansionPanel key={profileContextSchedule.id} defaultExpanded={false}>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography key="bn" variant="h6" gutterBottom>Day: {profileContextSchedule.profileDayType.displayName} - Schedule: {profileContextSchedule.profileDaySchedule.displayName}</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                      <Grid style={{ width: "100%" }}>
                        <ExpansionPanel key={profileContextSchedule.profileDayType.id} defaultExpanded={false}>
                          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography key="bn" variant="h6" gutterBottom>{profileContextSchedule.profileDayType.displayName}</Typography>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            <MuiForm schema={profileDayTypeSchema} formData={profileContextSchedule.profileDayType } onSubmit={(formData)=>handleProfileDayTypeSubmit(formData, profileContextSchedule.profileDayType.id)}>
                              <Button style={{ display: 'inline-block' }} variant="contained" color="primary" type="submit" >
                                Update ProfileDayType</Button>
                            </MuiForm>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>

                        <ExpansionPanel key={profileContextSchedule.profileDaySchedule.id} defaultExpanded={false}>
                          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography key="bn" variant="h6" gutterBottom>{profileContextSchedule.profileDaySchedule.displayName}</Typography>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            <MuiForm schema={profileContextSchedule.profileDaySchedule.payload.constructor===Array?profileDayScheduleArray:profileDayScheduleObject} formData={profileContextSchedule.profileDaySchedule} onSubmit={(formData)=>handleProfileDayScheduleSubmit(formData, profileContextSchedule.profileDaySchedule.id)}>
                              <Button style={{ display: 'inline-block' }} variant="contained" color="primary" type="submit" >
                                Update profileDaySchedule</Button>
                            </MuiForm>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                        </Grid>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    )
                    }
                    else
                    {
                      return null
                    }
                  }
                  )}
                </Grid>


              </ExpansionPanelDetails>
            </ExpansionPanel>
            )
          }
          )}
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
      
      

  );
}


const updateProfileContext = gql`
mutation updateProfileContext($id: ID!, $projectID: String!, $scalingType: String!, $scalingParameters: Json!) {
  updateProfileContext (
    id:$id
    projectID: $projectID
    scalingType: $scalingType
    scalingParameters: $scalingParameters
    ){
    id    
  }
}`;

const updateProfileDayType = gql`
mutation updateProfileDayType($id: ID!, $projectID: String!, $priority: Int!, $weekDays: [Int!]) {
  updateProfileDayType (
    id:$id
    projectID: $projectID
    priority: $priority
    weekDays: $weekDays
    ){
    id    
  }
}`;

const updateProfileDaySchedule = gql`
mutation updateProfileDaySchedule($id: ID!, $projectID: String!,  $payload: Json!) {
  updateProfileDaySchedule (
    id:$id
    projectID: $projectID
    payload: $payload
    ){
    id    
  }
}`;

const getProfileContexts = gql`
query getProfileContexts($projectID: String!) {
  profileContexts (projectID:$projectID){
    id
    displayName
    scalingParameters
    scalingType
    profileContextSchedules{id profileDayType{id displayName weekDays yearDays priority} profileDaySchedule {id displayName payload payloadType}}
    
  }
}`;

const profileContextSchema = {
"type":"object",
"properties": {
  "scalingParameters":{
    "type": "object",
    "properties":{
      "active":{
        "type":"number"
      },
      "inactive":{
        "type":"number"
      }
    }
  },

  "scalingType":{
    "type": "string"
  }
}

}

const profileDayScheduleObject = {
  "type": "object",
  "properties": {
    "payload":{
      "type": "object",
      "additionalProperties":{
        "type": "number"
      }
    }
  }
}

const profileDayScheduleArray = {
  "type": "object",
  "properties": {
    "payload":{
      "type": "array",
      "items":{
        "type": "string"
      }
    }
  }

}
const profileDayTypeSchema = {
  "type": "object",
  "properties": {
    "weekDays": {
      "type": "array",
      "items":{
        "type": "integer"
      }
        
  },
"priority": {
  "type": "integer",
    
},
  }
}


export default ConfigCalendar;
