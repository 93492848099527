import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Icon from '@material-ui/core/Icon';
import Hidden from '@material-ui/core/Hidden';

import ConfigSystemMetricEdit from './ConfigSystemMetricEdit'

const ConfigSpace = props => {
  const { projectID, spaceId, parentRefetch } = props

  const [ displayName, setDisplayName ] = useState("")
  const [ displayNameEditState, setDisplayNameEditState ] = useState(false)
  const [ openBuildingSpaceDialog, setOpenBuildingSpaceDialog ] = useState(false)
  const [ parentSpaceId, setParentSpaceId ] = useState("none")
  const [ selectedSystemMetricTemplateId, setSystemMetricTemplateId ] = useState("---")

  const { data, loading, error, refetch } = useQuery(getSpaceData, {
    variables: { spaceId, projectID },
    onCompleted: data => {
        data.space.displayName && (data.space.displayName.length > 0) && setDisplayName(data.space.displayName);
        !(data.space.displayName && (data.space.displayName.length > 0)) && setDisplayNameEditState(true);
        data.space.parentSpace && setParentSpaceId(data.space.parentSpace.id);
    }
  })

  const [ deleteSpaceMutation ] = useMutation(deleteSpace)
  const [ updateDisplayNameMutation ] = useMutation(updateDisplayName)
  const [ updateSpaceParentMutation ] = useMutation(updateSpaceParent)
  const [ createSystemMetricOnSpaceMutation ] = useMutation(createSystemMetricOnSpace)

  if (loading) return (<Grid><CircularProgress size={30} color="secondary" /></Grid>)
  if (error) return (<Grid><Typography gutterBottom>Error fetching Space data!</Typography></Grid>)
  if (!data.space) return (<Typography gutterBottom>No Space Found!</Typography>)

  const space = data.space
  const buildingSpaces = data.spaces
  const systemMetricTags = data.systemMetricTags

  const systemMetricTagCreatorConfigwizardId = systemMetricTags.filter(t => (t.key==="creator" && t.value==="configwizard")).map(t => (t.id))[0]

  const enableDisplayNameEdit = async () => { await setDisplayNameEditState(true) }
  const disableDisplayNameEdit = async () => { await setDisplayNameEditState(false) }

  const handleDisplayNameUpdate = async () => {
    await updateDisplayNameMutation({ variables: { spaceId, displayName }})
    await disableDisplayNameEdit()
    await refetch()
  }

  const handleParentSpaceIdChange = async (event) => {
    await setParentSpaceId(event.target.value);
    await updateSpaceParentMutation({
      variables: {
        spaceId: spaceId,
        parentSpaceId: event.target.value,
    }});
    await refetch()
  };

  const handleSpaceDelete = async () => {
    await deleteSpaceMutation({ variables: { spaceId }})
    await parentRefetch()
  }

  const closeBuildingSpaceDialog = async () => { setOpenBuildingSpaceDialog(false) };

  const handleSystemMetricAdd = async () => {
    if (selectedSystemMetricTemplateId !== "---") {
      await createSystemMetricOnSpaceMutation({
        variables: {
          projectID: projectID,
          spaceId: spaceId,
          systemMetricTemplateId: selectedSystemMetricTemplateId,
          payload:{},
          userInput:{},
          aggregation:"",
          systemMetricTags:[
            {"id": systemMetricTagCreatorConfigwizardId},
            {"id": space.systemTemplate.systemMetricTemplates.find(smt => (smt.id === selectedSystemMetricTemplateId)).possibleFunctionTags[0].id}
          ],
          rawTrajectories:[],
          profileContextCreateOneWithoutSystemMetricConversionsInput: null,
      }})
      await refetch()
    }
  }

  return (
    <ExpansionPanel TransitionProps={{ unmountOnExit: true }}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6" >{displayName}</Typography>
        <Typography variant="h6" style={{flex:1}}>&nbsp;</Typography>
        <Hidden xlDown={displayNameEditState}>
          <Icon onClick={enableDisplayNameEdit}>edit</Icon>
        </Hidden>
        <Hidden xlDown={!displayNameEditState}>
          <Input
            id="displayName-input"
            placeholder={space.systemTemplate.displayName + " Name"}
            value={displayName}
            onChange={(event) => {setDisplayName(event.target.value)}}
          />
          &nbsp;&nbsp;&nbsp;
          <Button size="small" variant="contained" color="primary" onClick={handleDisplayNameUpdate}>
            Update&nbsp;&nbsp;&nbsp;<Icon>save</Icon>
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button size="small" variant="contained" color="primary" onClick={disableDisplayNameEdit}>
            Cancel&nbsp;&nbsp;&nbsp;<Icon>cancel</Icon>
          </Button>
        </Hidden>
        <Typography variant="h6">&nbsp;</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1" gutterBottom>The parent space this space belongs to</Typography>
              </TableCell>
              <TableCell>
              </TableCell>
              <TableCell>
                <TextField
                  id="select-parentSpaceId"
                  select
                  value={parentSpaceId}
                  onChange={handleParentSpaceIdChange}
                  margin="normal"
                >
                  <MenuItem key="none" value="none">
                    -- Select space --
                  </MenuItem>
                  {buildingSpaces.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.displayName}
                    </MenuItem>
                  ))}
                </TextField>
              </TableCell>
              <TableCell>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1" gutterBottom>Configure New Metric</Typography>
              </TableCell>
              <TableCell>
                <TextField
                  id="select-system-metric-template"
                  select
                  value={selectedSystemMetricTemplateId}
                  onChange={(event) => {setSystemMetricTemplateId(event.target.value)}}
                  margin="normal"
                >
                  <MenuItem key={"---"} value={"---"}>
                    -- Select Metric to Add --
                  </MenuItem>
                  {space.systemTemplate.systemMetricTemplates
                    .filter(smt => (smt.possibleFunctionTags && (smt.possibleFunctionTags.length > 0)))
                    .sort((a, b) => (a.guiOrder - b.guiOrder || (a.displayName > b.displayName ? 1 : -1)))
                    .map(option => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.displayName}
                      </MenuItem>
                  ))}
                </TextField>
              </TableCell>
              <TableCell align="right">
                <Button size="small" variant="contained" color="primary" onClick={handleSystemMetricAdd}>
                  <Icon>add</Icon>&nbsp;&nbsp;&nbsp;Add&nbsp;
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </ExpansionPanelDetails>
      <Divider />
      <ExpansionPanelDetails>
        <Table>
          <TableBody>
            {Array.from(space.systemMetrics)
              .sort((a, b) => (a.systemMetricTemplate.guiOrder - b.systemMetricTemplate.guiOrder || (a.systemMetricTemplate.displayName > b.systemMetricTemplate.displayName ? 1 : -1)))
              .map((systemMetric, index) => {
              return ((systemMetric.systemMetricTags.findIndex(t => t.key === "function") > -1)
              ? (
                <ConfigSystemMetricEdit
                  key={systemMetric.id}
                  projectID={projectID}
                  systemMetricId={systemMetric.id}
                  parentRefetch={refetch} />
              ) : ( null ))
            })}
          </TableBody>
        </Table>
      </ExpansionPanelDetails>
      <Divider />
      <ExpansionPanelActions>
        <Button size="small" variant="contained" color="primary" onClick={handleSpaceDelete} >
          <Icon>delete</Icon>&nbsp;&nbsp;&nbsp;Delete&nbsp;{space.systemTemplate.displayName}&nbsp;"{space.displayName}"&nbsp;
        </Button>
        <Dialog
          open={openBuildingSpaceDialog}
          onClose={closeBuildingSpaceDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Cannot delete main Building Space"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              The Building Space is required for a correct functioning of the application.
              Please create a hierarchical structure by adding all spaces as children of the Building Space or descendants thereof.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeBuildingSpaceDialog} color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </ExpansionPanelActions>
    </ExpansionPanel>
  );
}

const deleteSpace = gql`
mutation deleteSpace($spaceId: ID!) {
  deleteSpace(id: $spaceId) {
    id
  }
}`;

const updateDisplayName = gql`
mutation updateDisplayName($spaceId: ID!, $displayName: String!) {
  updateSpaceDisplayName (
    id: $spaceId
    displayName: $displayName
  ) { id displayName }
}`;

const updateSpaceParent = gql`
mutation updateSpaceParent($spaceId: ID!, $parentSpaceId: ID!) {
  updateSpaceParent (
    id: $spaceId
    parentSpaceId: $parentSpaceId
  ) {
    id
    displayName
    parentSpace { id displayName }
  }
}`;

const createSystemMetricOnSpace = gql`
mutation createSystemMetricOnSpace($projectID: String!, $spaceId: ID!, $systemMetricTemplateId: ID!, $payload: Json!, $userInput:Json!, $aggregation:String!, $systemMetricTags: [SystemMetricTagWhereUniqueInput!], $rawTrajectories: [RawTrajectoryWhereUniqueInput!], $profileContextCreateOneWithoutSystemMetricConversionsInput: ProfileContextCreateOneWithoutSystemMetricConversionsInput) {
  createSystemMetricOnSpace(
    projectID: $projectID
    spaceId: $spaceId
    systemMetricTemplateId: $systemMetricTemplateId
    payload: $payload
    userInput: $userInput
    aggregation: $aggregation
    systemMetricTags: $systemMetricTags
    rawTrajectories: $rawTrajectories
    profileContextCreateOneWithoutSystemMetricConversionsInput: $profileContextCreateOneWithoutSystemMetricConversionsInput
    ) { id }
}`;

const getSpaceData = gql`
query getSpaceData($spaceId: ID!, $projectID: String!) {
  systemMetricTags {id key value}
  spaces (projectID: $projectID) {
    id
    displayName
    slug
  }
  space (id: $spaceId) {
    id
    displayName
    slug
    systemTemplate {
      id
      name
      displayName
      systemMetricTemplates (where: {level:0}) {
        id
        name
        displayName
        guiOrder
        possibleFunctionTags {id key value}
      }
    }
    parentSpace {
      id
      displayName
      slug
    }
    systemMetrics (where: {systemMetricTemplate: {level:0} systemMetricTags_some:{key:"creator", value:"configwizard"}}) {
      id
      userInput
      payload
      systemMetricTags {id key value}
      systemMetricTemplate {
        id
        name
        displayName
        guiOrder
        possibleFunctionTags {id key value}
      }
    }
  }
}`;

export default ConfigSpace
