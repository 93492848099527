import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag'

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

import Icon from '@material-ui/core/Icon';
import Timeline from '@material-ui/icons/Timeline';
import Save from '@material-ui/icons/Save';

import ConfigTagSelection from './ConfigTagSelection';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction='down' {...props} ref={ref} />
))

const SYSTEMMETRIC_TAG_FUNCTION_LIST = [
  {'slug': 'sensor#tag#',               "displayName": "Sensor (BMS Tags)",            "function": "sensor",     "source": "tag",     "timewindow":"past", "rwc": null},
  {'slug': 'nominal#value#',            "displayName": "Nominal (Fixed)",              "function": "nominal",    "source": "value",   "timewindow":"all",  "rwc": null},
  {'slug': 'model#profile#',            "displayName": "Model (Profile)",              "function": "model",      "source": "profile", "timewindow":"all",  "rwc": null},
  {'slug': 'setpoint#tag#read',         "displayName": "Setpoint (BMS Read)",          "function": "setpoint",   "source": "tag",     "timewindow":"all",  "rwc": "read"},
  {'slug': 'setpoint#tag#write',        "displayName": "Setpoint (BMS Write)",         "function": "setpoint",   "source": "tag",     "timewindow":"past", "rwc": "write"},
  {'slug': 'setpoint#value#control',    "displayName": "Setpoint (Control Value)",     "function": "setpoint",   "source": "value",   "timewindow":"all",  "rwc": "control"},
  {'slug': 'setpoint#profile#control',  "displayName": "Setpoint (Control Profile)",   "function": "setpoint",   "source": "profile", "timewindow":"all",  "rwc": "control"},
  {'slug': 'limit_low#tag#read',        "displayName": "Low Limit (BMS Read)",         "function": "limit_low",  "source": "tag",     "timewindow":"past", "rwc": "read"},
  {'slug': 'limit_low#tag#write',       "displayName": "Low Limit (BMS Write)",        "function": "limit_low",  "source": "tag",     "timewindow":"past", "rwc": "write"},
  {'slug': 'limit_low#value#control',   "displayName": "Low Limit (Control Value)",    "function": "limit_low",  "source": "value",   "timewindow":"past", "rwc": "control"},
  {'slug': 'limit_low#profile#control', "displayName": "Low Limit (Control Profile)",  "function": "limit_low",  "source": "profile", "timewindow":"past", "rwc": "control"},
  {'slug': 'alert_low#value#',          "displayName": "Low Alert (Fixed)",            "function": "alert_low",  "source": "value",   "timewindow":"past", "rwc": null},
  {'slug': 'alert_low#profile#',        "displayName": "Low Alert (Profile)",          "function": "alert_low",  "source": "profile", "timewindow":"past", "rwc": null},
  {'slug': 'limit_high#tag#read',       "displayName": "High Limit (BMS Read)",        "function": "limit_high", "source": "tag",     "timewindow":"past", "rwc": "read"},
  {'slug': 'limit_high#tag#write',      "displayName": "High Limit (BMS Write)",       "function": "limit_high", "source": "tag",     "timewindow":"past", "rwc": "write"},
  {'slug': 'limit_high#value#control',  "displayName": "High Limit (Control Value)",   "function": "limit_high", "source": "value",   "timewindow":"all",  "rwc": "control"},
  {'slug': 'limit_high#profile#control',"displayName": "High Limit (Control Profile)", "function": "limit_high", "source": "profile", "timewindow":"all",  "rwc": "control"},
  {'slug': 'alert_high#value#',         "displayName": "High Alert (Fixed)",           "function": "alert_high", "source": "value",   "timewindow":"past", "rwc": null},
  {'slug': 'alert_high#profile#',       "displayName": "High Alert (Profile)",         "function": "alert_high", "source": "profile", "timewindow":"past", "rwc": null},
]

const getFunctionFromSlug    = (slug) => (SYSTEMMETRIC_TAG_FUNCTION_LIST.find(f => f.slug === slug).function)
const getSourceFromSlug      = (slug) => (SYSTEMMETRIC_TAG_FUNCTION_LIST.find(f => f.slug === slug).source)
const getTimewindowFromSlug  = (slug) => (SYSTEMMETRIC_TAG_FUNCTION_LIST.find(f => f.slug === slug).timewindow)
const getRwcFromSlug         = (slug) => (SYSTEMMETRIC_TAG_FUNCTION_LIST.find(f => f.slug === slug).rwc)
const getPayloadTypeFromSlug = (slug) => ((getSourceFromSlug(slug) === "value") ? "value" : "timeseries")

const getPossibleFunctionObjects = (possibleFunctionTags) => {
  return (possibleFunctionTags && possibleFunctionTags.length)
  ? SYSTEMMETRIC_TAG_FUNCTION_LIST.filter(f => possibleFunctionTags.findIndex(t => t.value === f.function) > -1)
  : SYSTEMMETRIC_TAG_FUNCTION_LIST
}

const getFunctionSlugFromSystemMetric = (systemMetric) => {
  if (systemMetric.systemMetricTags && systemMetric.systemMetricTags.filter(tag => tag.key === "function").length > 0) {
    const function_part = systemMetric.systemMetricTags.filter(tag => tag.key === "function")[0].value
    const source_part = (function_part === "sensor")
    ? "tag"
    : (function_part === "nominal")
    ? "value"
    : (function_part === "model")
    ? "profile"
    : (systemMetric.systemMetricConversionIn && systemMetric.systemMetricConversionIn.sourceRawTrajectories && systemMetric.systemMetricConversionIn.sourceRawTrajectories.length)
    ? "tag"
    : (systemMetric.systemMetricConversionIn && systemMetric.systemMetricConversionIn.sourceProfileContext)
    ? "profile"
    : "value"
    const rwc_part = systemMetric.systemMetricTags.filter(tag => tag.key === "rwc").length > 0
    ? systemMetric.systemMetricTags.filter(tag => tag.key === "rwc")[0].value
    : ""
    return function_part + "#" + source_part + "#" + rwc_part
  } else if (systemMetric.userInput && systemMetric.userInput.functionSlug) {
    return systemMetric.userInput.functionSlug.toLowerCase()
  } else if (systemMetric.systemMetricTemplate && systemMetric.systemMetricTemplate.possibleFunctionTags.length > 0) {
    return SYSTEMMETRIC_TAG_FUNCTION_LIST.find(f => f.function === systemMetric.systemMetricTemplate.possibleFunctionTags[0].value).slug
  }
}

const ConfigSystemMetricEdit = props => {
  const { projectID, systemMetricId , parentRefetch } = props

  const [ profile, setProfile] = useState("none")
  const [ systemMetricValue, setSystemMetricValue] = useState("")
  const [ unit, setUnit] = useState("")
  const [ tagSelectionOpen, setTagSelectionOpen] = useState(false)
  const [ aggregation, setAggregation] = useState("")
  const [ selectedFunctionSlug, setFunctionSlug] = useState("")
  const [ nameFilterChips, setNameFilterChips] = useState([])
  const [ selectedTrajectoryTagNames, setTrajectoryTagNames] = useState([])
  const [ selectedTrajectoriesUserInput, setTrajectoriesUserInput] = useState(new Map())

  const { data, loading, error, refetch } = useQuery(getSystemMetricData, {
    variables: { systemMetricId, projectID },
    onCompleted: data => {
      // Data Init
      setFunctionSlug(getFunctionSlugFromSystemMetric(data.systemMetrics[0]))
      setProfile((data.systemMetrics[0].systemMetricConversionIn && data.systemMetrics[0].systemMetricConversionIn.sourceProfileContext) ? data.systemMetrics[0].systemMetricConversionIn.sourceProfileContext.id : data.systemMetrics[0].userInput && data.systemMetrics[0].userInput.profile ? data.systemMetrics[0].userInput.profile : "none")
      setSystemMetricValue((data.systemMetrics[0].payload !== null) && (data.systemMetrics[0].payload.value !== null) ? data.systemMetrics[0].payload.value : data.systemMetrics[0].userInput && (data.systemMetrics[0].userInput.systemMetricValue !== null) ? data.systemMetrics[0].userInput.systemMetricValue : "")
      setTrajectoryTagNames(data.systemMetrics[0].systemMetricConversionIn && data.systemMetrics[0].systemMetricConversionIn.sourceRawTrajectories ? data.systemMetrics[0].systemMetricConversionIn.sourceRawTrajectories.map(t => t.tagname): [])
      // User Input
      setUnit(data.systemMetrics[0].userInput && data.systemMetrics[0].userInput.unit ? data.systemMetrics[0].userInput.unit : data.systemMetrics[0].systemMetricTemplate.metric.defaultDisplayUnitString)
      setAggregation(data.systemMetrics[0].userInput && data.systemMetrics[0].userInput.aggregation ? data.systemMetrics[0].userInput.aggregation : data.systemMetrics[0].systemMetricTemplate.metric.defaultOperationFormula)
      setNameFilterChips(data.systemMetrics[0].userInput && data.systemMetrics[0].userInput.nameFilterChips ? data.systemMetrics[0].userInput.nameFilterChips : nameFilterChips)
      setTrajectoriesUserInput(
        data.systemMetrics[0].systemMetricConversionIn && data.systemMetrics[0].systemMetricConversionIn.sourceRawTrajectories && data.systemMetrics[0].systemMetricConversionIn.sourceRawTrajectories.length
        ? new Map(data.systemMetrics[0].systemMetricConversionIn.sourceRawTrajectories.map(rt => [rt.id, new Map()]))
        : data.systemMetrics[0].userInput && data.systemMetrics[0].userInput.selectedTrajectories
        ? new Map(data.systemMetrics[0].userInput.selectedTrajectories.map(id => [id, new Map()]))
        : new Map()
      )
    }
  })

  const [ updateSystemMetricMutation           ] = useMutation(updateSystemMetric)
  const [ deleteSystemMetricMutation           ] = useMutation(deleteSystemMetric)
  const [ createSystemMetricConversionMutation ] = useMutation(createSystemMetricConversion)
  const [ updateSystemMetricConversionMutation ] = useMutation(updateSystemMetricConversion)
  const [ deleteSystemMetricConversionMutation ] = useMutation(deleteSystemMetricConversion)
  const [ createRawTrajectoryMutation          ] = useMutation(createRawTrajectory)
  const [ updateRawTrajectoryMutation          ] = useMutation(updateRawTrajectory)

  if (loading) return (<TableRow><TableCell size="small"><CircularProgress size={20} color="secondary" /></TableCell></TableRow>)
  if (error)   return (<TableRow><TableCell size="small"><Typography gutterBottom>Error fetching SystemMetric data!</Typography></TableCell></TableRow>)
  if (!data.systemMetrics || data.systemMetrics.length === 0) return (<TableRow><TableCell size="small"><Typography gutterBottom>No SystemMetric Found!</Typography></TableCell></TableRow>)

  const systemMetric     = data.systemMetrics[0]
  const systemMetricTags = data.systemMetricTags
  const profileContexts  = data.profileContexts

  const systemMetricTagCreatorConfigwizardId =         systemMetricTags.filter(t => (t.key==="creator" && t.value==="configwizard")).map(t =>           (t.id))[0]
  const systemMetricTagTimewindowMap         = new Map(systemMetricTags.filter(t => (t.key==="timewindow"                         )).map(t => ([t.value, t.id])))
  const systemMetricTagFunctionMap           = new Map(systemMetricTags.filter(t => (t.key==="function"                           )).map(t => ([t.value, t.id])))
  const systemMetricTagRwcMap                = new Map(systemMetricTags.filter(t => (t.key==="rwc"                                )).map(t => ([t.value, t.id])))

  const possibleFunctionObjects = getPossibleFunctionObjects(systemMetric.systemMetricTemplate.possibleFunctionTags)

  const handleSelectedFunctionSlugChange = event => { setFunctionSlug(     event.target.value) }
  const handleUnitChange                 = event => { setUnit(             event.target.value) }
  const handleProfileChange              = event => { setProfile(          event.target.value) }
  const handleSystemMetricValueChange    = event => { setSystemMetricValue(event.target.value) }

  const handleTagSelectionOpen   = () => { setTagSelectionOpen(true ) };
  const handleTagSelectionClose  = () => { setTagSelectionOpen(false) };
  const handleTagSelectionCancel = () => { setTagSelectionOpen(false) };

  const createRT = async (rt) => {
    rt.id_org = rt.id;
    if (rt.id.substr(0, 3) !== "s3-"){ return rt;}
    //--- Create RT, if not already present in Prisma ---
    const tagname = rt.id.substr(3);
    const rt_new = await createRawTrajectoryMutation({
      variables: {
        tagname:   tagname,
        projectID: projectID
      }
    }).then(response => response.data.createRawTrajectory);
    rt_new.id_org = rt.id;
    return rt_new;
  }

  const createRTs = async (rts) => {
    //return await rts.map( async (rt) => {await createRT(rt)}); // Doesn't work, returns promise!
    var rts_new = [];
    for (const rt of rts){
      const rt_new = await createRT(rt);
      rts_new.push(rt_new);
    }
    return rts_new;
  }

  const updateRT = (id, map) => {
    //--- Update UserInput ---
    updateRawTrajectoryMutation({
      variables: {
          id: id,
          unit:              map?map.get("unit"      ):null,
          cumulative:        map?(map.get("cumulative")!=="No"):false,
          rating:            map?parseInt(map.get("rating"    ), 10):3600,
          counterResetValue: map?(map.get("cumulative")==="Reset" ? parseFloat(map.get("resetValue")): null):null,
      },
      refetchQueries: [{
          query: updateRawTrajectoryCache,
          variables: {
            id: id,
          }
      }],
    })
  }

  const handleTagSelectionSave = async (selectedTrajectories_raw, userInput, aggregation, nameFilterChips) => {
    const selectedTrajectories          = await createRTs(selectedTrajectories_raw);
    const selectedTrajectoriesUserInput = new Map(selectedTrajectories.map( rt => ([rt.id, userInput.get(rt.id_org)]) ));
    _handleTagSelectionSave(selectedTrajectories, selectedTrajectoriesUserInput, aggregation, nameFilterChips);
    refetch();
  }

  const _handleTagSelectionSave = (selectedTrajectories, selectedTrajectoriesUserInput, aggregation, nameFilterChips) => {

    setTrajectoryTagNames(selectedTrajectories.map(rt => rt.tagname));
    setTrajectoriesUserInput(selectedTrajectoriesUserInput);
    setAggregation(aggregation);
    setNameFilterChips(nameFilterChips);

    if (typeof localStorage !== 'undefined') { localStorage.setItem("nameFilterChips", JSON.stringify(nameFilterChips)); };

    Array.from(selectedTrajectoriesUserInput).forEach(([id, map]) => {
        updateRT(id,map);
    });

    _saveSystemMetric(systemMetricValue,profile,unit,aggregation,nameFilterChips,selectedFunctionSlug,selectedTrajectoriesUserInput);
    handleTagSelectionClose();
  };

  const handleSave = () => {
    _saveSystemMetric(systemMetricValue,profile,unit,aggregation,nameFilterChips,selectedFunctionSlug,selectedTrajectoriesUserInput);
    refetch();
  };

  const _saveSystemMetric = (systemMetricValue,profile,unit,aggregation,nameFilterChips,selectedFunctionSlug,selectedTrajectoriesUserInput) => {
    const selectedTrajectoryIds = Array.from(selectedTrajectoriesUserInput).map(([id,rt])=>id);
    saveSystemMetric(
      {
        systemMetricValue: systemMetricValue,
        profile: profile,
        unit: unit,
        selectedTrajectories: selectedTrajectoryIds,
        aggregation: aggregation,
        nameFilterChips: nameFilterChips,
      },
      getPayloadTypeFromSlug(selectedFunctionSlug),
      getPayloadTypeFromSlug(selectedFunctionSlug)==="value"   ? {value: parseFloat(systemMetricValue), unit: unit} : {},
      getSourceFromSlug(     selectedFunctionSlug)==="profile" ? profile : null,
      getSourceFromSlug(     selectedFunctionSlug)==="tag"     ? selectedTrajectoryIds : [],
      aggregation,
    )
  }

  const saveSystemMetric = (systemMetricUserInput, payloadType, payload, profileContextId, rawTrajectoriesIds, aggregation) => {
    const refetchQuery = {
      query: getSystemMetricData,
      variables: { systemMetricId, projectID }
    }
    updateSystemMetricMutation({
      variables: {
        id: systemMetricId,
        userInput: systemMetricUserInput,
        payloadType: payloadType,
        payload: payload,
        systemMetricTags: [
          {"id": systemMetricTagCreatorConfigwizardId},
          {"id": systemMetricTagFunctionMap.get(getFunctionFromSlug(selectedFunctionSlug))},
          {"id": systemMetricTagTimewindowMap.get(getTimewindowFromSlug(selectedFunctionSlug))}
        ].concat(
          getRwcFromSlug(selectedFunctionSlug)
          ? [{"id": systemMetricTagRwcMap.get(getRwcFromSlug(selectedFunctionSlug))}]
          : []
        )
      },
      refetchQueries: [refetchQuery],
    }).then(response => {
    // handle the response
    });
    if(systemMetric.systemMetricConversionIn) {
      if (getSourceFromSlug(selectedFunctionSlug)!=="value") {
        updateSystemMetricConversionMutation({
          variables: {
            id: systemMetric.systemMetricConversionIn.id,
            aggregation: aggregation,
            rawTrajectories: rawTrajectoriesIds.map(id => ({"id":id})),
            profileContextId: profileContextId,
          },
          refetchQueries: [refetchQuery],
        }).then(response => {
        // handle the response
        });
      } else {
        deleteSystemMetricConversionMutation({
          variables: {
            systemMetricConversionId: systemMetric.systemMetricConversionIn.id,
          },
          refetchQueries: [refetchQuery],
        }).then(response => {
        // handle the response
        });
      }
    } else {
      if (getSourceFromSlug(selectedFunctionSlug)!=="value") {
        createSystemMetricConversionMutation({
          variables: {
            targetSystemMetricId: systemMetricId,
            aggregation: aggregation,
            rawTrajectories: rawTrajectoriesIds.map(id => ({"id":id})),
            profileContextId: profileContextId,
          },
          refetchQueries: [refetchQuery],
        }).then(response => {
        // handle the response
        });
      }
    }
  }

  const handleSystemMetricDelete = async () => {
    await deleteSystemMetricMutation({ variables: { systemMetricId }})
    await parentRefetch()
  }

  return (
    <TableRow>
      <TableCell size="small">
        <Tooltip id="tooltip-name" title={systemMetric.systemMetricTemplate.description} placement="bottom-start">
          <Typography variant="subtitle1" gutterBottom>{systemMetric.systemMetricTemplate.displayName}</Typography>
        </Tooltip>
        <Dialog
          fullScreen
          open={tagSelectionOpen}
          onClose={handleTagSelectionClose}
          TransitionComponent={Transition}
        >
          <ConfigTagSelection
            projectID={projectID}
            systemDisplayName={systemMetric.system ? systemMetric.system.displayName : ""}
            systemMetricTemplate={systemMetric.systemMetricTemplate}
            systemMetric={systemMetric}
            selectedTrajectoriesUserInput={selectedTrajectoriesUserInput}
            aggregation={aggregation}
            nameFilterChips={nameFilterChips}
            onCancel={handleTagSelectionCancel}
            onSave={handleTagSelectionSave}
          />
        </Dialog>
      </TableCell>
      <TableCell key="function-value-input-cell" size="small">
        <TextField
          id="select-function-value"
          select
          value={selectedFunctionSlug}
          onChange={handleSelectedFunctionSlugChange}
          margin="normal"
          disabled={(possibleFunctionObjects.length < 2)}
        >
          {possibleFunctionObjects.map(option => (
            <MenuItem key={option.slug} value={option.slug}>
              {option.displayName}
            </MenuItem>
          ))}
        </TextField>
      </TableCell>
      {(selectedFunctionSlug.search("#tag") >= 0)
      ? ([
        <TableCell key="monitoring-input-cell" colSpan={2} size="small">
          <Tooltip id="tooltip-selected"
            title={
              selectedTrajectoryTagNames.length > 0
              ? selectedTrajectoryTagNames.map(t => (
                <Typography key={t}>{t}</Typography>
              ))
              : ""
            }
            placement="bottom-start">
            <Typography>
              {Array.from(selectedTrajectoryTagNames).length > 0 ? "(" + Array.from(selectedTrajectoryTagNames).length + " selected)" : " "}
            </Typography>
          </Tooltip>
        </TableCell>
        ,
        <TableCell key="monitoring-button-cell" size="small">
          <Button
            variant="contained"
            size="small"
            onClick={handleTagSelectionOpen}>
            <Timeline />
            &nbsp;&nbsp;BMS&hellip;
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button size="small" variant="contained" color="primary" onClick={handleSystemMetricDelete} >
            <Icon>delete</Icon>
          </Button>
        </TableCell>
      ])
      : (selectedFunctionSlug.search("#value") >= 0)
      ? ([
        <TableCell key="parameter-input-cell" size="small">
          <Input
            id="systemMetricValue-input"
            value={systemMetricValue}
            onChange={handleSystemMetricValueChange}
            style={{width: 75}}
          />
        </TableCell>
        ,
        <TableCell key="parameter-unit-cell" size="small">
          <TextField
            id="select-unit"
            select
            value={unit}
            onChange={handleUnitChange}
            margin="normal"
            disabled={(systemMetric.systemMetricTemplate.metric.alternativeUnitStrings.length===0)}
          >
            <MenuItem key={systemMetric.systemMetricTemplate.metric.defaultDisplayUnitString} value={systemMetric.systemMetricTemplate.metric.defaultDisplayUnitString}>
              {systemMetric.systemMetricTemplate.metric.defaultDisplayUnitString}
            </MenuItem>
            {systemMetric.systemMetricTemplate.metric.alternativeUnitStrings.map(option => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </TableCell>
        ,
        <TableCell key="button-cell" size="small">
          <Button
            variant="contained"
            size="small"
            onClick={handleSave}>
            <Save />
            &nbsp;&nbsp;Save&nbsp;
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button size="small" variant="contained" color="primary" onClick={handleSystemMetricDelete} >
            <Icon>delete</Icon>
          </Button>
        </TableCell>
      ])
      : // (selectedFunctionSlug.search("#profile") >= 0)
      ([
        <TableCell key="profile-select-cell" colSpan={2} size="small">
          <TextField
            id="select-profile"
            select
            value={profile}
            onChange={handleProfileChange}
            margin="normal"
          >
            <MenuItem key="none" value="none">
              -- Select profile --
            </MenuItem>
            {profileContexts.map(option => (
              <MenuItem key={option.id} value={option.id}>
                {option.displayName}
              </MenuItem>
            ))}
          </TextField>
        </TableCell>
        ,
        <TableCell key="button-cell" size="small">
          <Button
            variant="contained"
            size="small"
            onClick={handleSave}>
            <Save />
            &nbsp;&nbsp;Save&nbsp;
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button size="small" variant="contained" color="primary" onClick={handleSystemMetricDelete} >
            <Icon>delete</Icon>
          </Button>
        </TableCell>
      ])}
    </TableRow>
  );
}

const updateSystemMetric = gql`
mutation updateSystemMetric($id: ID!, $payloadType: String!, $payload: Json!, $userInput: Json!, $systemMetricTags:[SystemMetricTagWhereUniqueInput!]) {
  updateSystemMetric(
    id: $id
    payloadType: $payloadType
    payload: $payload
    userInput: $userInput
    systemMetricTags: $systemMetricTags
  ) {
    id
    payloadType
    payload
    userInput
    systemMetricTags {id key value}
  }
}`;

const createSystemMetricConversion = gql`
mutation createSystemMetricConversion($targetSystemMetricId: ID!, $aggregation:String!, $rawTrajectories: [RawTrajectoryWhereUniqueInput!], $profileContextId: ID) {
  createSystemMetricConversion(
    targetSystemMetricId: $targetSystemMetricId
    operationFormula: $aggregation
    profileContextId: $profileContextId
    sourceRawTrajectories: $rawTrajectories
  ) {
    id
    operationFormula
    targetSystemMetric { id }
    sourceProfileContext { id }
    sourceRawTrajectories { id }
  }
}`;

const deleteSystemMetric = gql`
mutation deleteSystemMetric($systemMetricId: ID!) {
  deleteSystemMetric(id: $systemMetricId) {
    id
  }
}`;

const updateSystemMetricConversion = gql`
mutation updateSystemMetricConversion($id: ID!, $aggregation:String!, $rawTrajectories: [RawTrajectoryWhereUniqueInput!], $profileContextId: ID) {
  updateSystemMetricConversion(
      id: $id
      operationFormula: $aggregation
      profileContextId: $profileContextId
      sourceRawTrajectories: $rawTrajectories
  ) {
    id
    operationFormula
    sourceProfileContext { id }
    sourceRawTrajectories { id }
  }
}`;

const deleteSystemMetricConversion = gql`
mutation deleteSystemMetricConversion($systemMetricConversionId: ID!) {
  deleteSystemMetricConversion(id: $systemMetricConversionId) {
    id
  }
}`;

const createRawTrajectory = gql`
mutation createRawTrajectory($tagname: String!, $projectID: String!) {
  createRawTrajectory(
    tagname: $tagname
    projectID: $projectID
  ) {
    id
    tagname
  }
}`;

const updateRawTrajectory = gql`
mutation updateRawTrajectory($id: ID!, $unit: String!, $cumulative: Boolean!, $rating: Int!, $counterResetValue: Float) {
  updateRawTrajectory(
    id: $id
    userInputUnit: $unit
    userInputCumulative: $cumulative
    userInputRating: $rating
    userInputCounterResetValue: $counterResetValue
  ) {
    id
    tagname
    userInputUnit
    userInputCumulative
    userInputRating
    userInputCounterResetValue
    autoSuggestUnit
    autoSuggestCumulative
    autoSuggestCounterResetValue
  }
}`;

const updateRawTrajectoryCache = gql`
query updateRawTrajectoryCache($id:ID!) {
  rawTrajectory(
    id: $id
  ) {
    id
    tagname
    userInputUnit
    userInputCumulative
    userInputRating
    userInputCounterResetValue
    autoSuggestUnit
    autoSuggestCumulative
    autoSuggestCounterResetValue
  }
}`;

const getSystemMetricData = gql`
query getSystemMetricData($systemMetricId: ID!, $projectID: String!) {
  systemMetricTags {
    id
    key
    value
  }
  profileContexts (projectID: $projectID) {
    id
    displayName
  }
  systemMetrics (where: {id:$systemMetricId}) {
    id
    userInput
    payload
    systemMetricTags {id key value}
    system {id displayName}
    systemMetricTemplate {
      id
      name
      description
      displayName
      level
      possibleFunctionTags {id key value}
      metric {
        id
        defaultOperationFormula
        defaultDisplayUnitString
        alternativeUnitStrings
      }
    }
    systemMetricConversionIn {
      id
      operationFormula
      operationInputMap
      sourceProfileContext {id displayName}
      sourceRawTrajectories {
        id
        tagname
        userInputUnit
        userInputCumulative
        userInputRating
        userInputCounterResetValue
        autoSuggestUnit
        autoSuggestCumulative
        autoSuggestCounterResetValue
      }
    }
  }
}`;

export default ConfigSystemMetricEdit