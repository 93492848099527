const GraphQLUriMap = new Map([
    ["__localhost_4000__", "http://localhost:4000/"],
    ["____kubernetes____", "http://localhost:30444/"],
    ["_____minikube_____", "http://minikube:30444/"],
    ["____development___", "https://graphql.dev.synaptiqbuilding.com/"],
    ["____production____", "https://graphql.deltaq.io/"],
    ["____deltaq_prod___", "https://graphql.deltaq.io/"],
    ["____deltaq_demo___", "https://graphql.demo.deltaq.io/"],
    ["app.deltaq.re", "https://graphql.deltaq.re/"],
    ["app.dev.synaptiqbuilding.com", "https://graphql.dev.synaptiqbuilding.com/"],
    ["app.synaptiqbuilding.com", "https://graphql.synaptiqbuilding.com/"],
]);

const getGraphQLUriFromWindowLocation = () => (
    window.location.href.indexOf("minikube") >= 0
    ? GraphQLUriMap.get("_____minikube_____")
    : window.location.href.indexOf("localhost:3000") >= 0
    ? GraphQLUriMap.get("____kubernetes____")
    : window.location.href.indexOf("localhost:30080") >= 0
    ? GraphQLUriMap.get("____kubernetes____")  // provide server for kubernetes testing
    : window.location.href.indexOf("localhost") >= 0
    ? GraphQLUriMap.get("____development___")  // provide server for local testing
    : window.location.href.indexOf("demo.deltaq.io") >= 0
    ? GraphQLUriMap.get("____deltaq_demo___")  // provide server for demo env
    : window.location.href.split('/').length > 1 && GraphQLUriMap.has(window.location.host)
    ? GraphQLUriMap.get(window.location.host)
    : GraphQLUriMap.get("____production____")  // provide default fallback (e.g. netlify staging domains)
);

const GraphQLAuthClientIDMap = new Map([
    ["____development___", "COd6mXL1k85D9HmUsGJMTd3xC4ccNCmY"],
    ["____production____", "7YkT6xxScrBBZMzmF0DAXx1wcOD4cwbR"],
    ["____deltaq_prod___", "rdN0YHmLGwpSnc8AGfK8QMZsIWveAO0O"],
    ["____deltaq_demo___", "auMdOvppY6VbDQx9WzqtaWYSYcSppb9U"],
]);

const getGraphQLAuthClientIDFromWindowLocation = () => (
    window.location.href.indexOf("localhost") >= 0 ||
    window.location.href.indexOf("minikube") >= 0 ||
    window.location.href.indexOf("graphql.dev.synaptiqbuilding.com") >= 0
    ? GraphQLAuthClientIDMap.get("____development___")  // provide ID for testing
    : window.location.href.indexOf("demo.deltaq.") >= 0
    ? GraphQLAuthClientIDMap.get("____deltaq_demo___")
    : window.location.href.indexOf(".deltaq.") >= 0
    ? GraphQLAuthClientIDMap.get("____deltaq_prod___")
    : GraphQLAuthClientIDMap.get("____production____")
);

const AppSettings = {
    GraphQLUri: getGraphQLUriFromWindowLocation(),
    GraphQLAuthDomain: "sqb.eu.auth0.com",
    GraphQLAuthClientID: getGraphQLAuthClientIDFromWindowLocation(),
    GraphQLAuthAudience: "https://graphql.synaptiqbuilding.com/",
    //
    YEARS_OF_INVOICES_PRIOR_TO_ACTIVATION: 3,
    BuildingFeatureTriggerUrl: getGraphQLUriFromWindowLocation() + "msg/project:command:buildingfeature:trigger",
    //
    API_BASE_URL: "https://api.deltaq.io",
    // API_BASE_URL: process.env.TIMESERIES_API_BASE_URL?process.env.TIMESERIES_API_BASE_URL:window.location.host==='localhost:3000'?'http://localhost:30681':"https://api.deltaq.io",
}

export default AppSettings;
