import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'

import { LinkTab } from '../components/Common'
import { Switch, Route, Redirect } from 'react-router-dom';

import Tabs from '@material-ui/core/Tabs'

import OperationCustom from '../components/OperationCustom'
import OperationComfort from '../components/OperationComfort'
import OperationSpace from '../components/OperationSpace'
import OperationAHU from '../components/OperationAHU'
import OperationHVAC from '../components/OperationHVAC'
import OperationCircuit from '../components/OperationCircuit'
import OperationModel from '../components/OperationModel'
import OperationControl from '../components/OperationControl'

const styles = theme => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
});

const Operation = props => {
    const { classes, match, location } = props;
    const url = location.pathname
    if (url === match.url) return  <Redirect to={`${match.url}/comfort`} push={false} />
    return (
        <div className={classes.root}>
            <Tabs value={url} onChange={() => null}>
                <LinkTab value={`${match.url}/comfort`} to={`${match.url}/comfort`} label="Comfort" />
                <LinkTab value={`${match.url}/space`} to={`${match.url}/space`}     label="Spaces" />
                <LinkTab value={`${match.url}/ahu`} to={`${match.url}/ahu`}         label="AHU" />
                <LinkTab value={`${match.url}/hvac`} to={`${match.url}/hvac`}       label="HVAC" />
                <LinkTab value={`${match.url}/circuit`} to={`${match.url}/circuit`} label="Circuits" />
                {/* <LinkTab value={`${match.url}/model`} to={`${match.url}/model`}     label="Model" /> */}
                <LinkTab value={`${match.url}/control`} to={`${match.url}/control`} label="Control" />
                {/* <LinkTab value={`${match.url}/custom`} to={`${match.url}/custom`}   label="Custom" /> */}
            </Tabs>
            <Switch>
                <Route path={`${match.path}/comfort`} component={OperationComfort} />
                <Route path={`${match.path}/space`} component={OperationSpace} />
                <Route path={`${match.path}/ahu`} component={OperationAHU} />
                <Route path={`${match.path}/hvac`} component={OperationHVAC} />
                <Route path={`${match.path}/circuit`} component={OperationCircuit} />
                <Route path={`${match.path}/model`} component={OperationModel} />
                <Route path={`${match.path}/control`} component={OperationControl} />
                <Route path={`${match.path}/custom`} component={OperationCustom} />
                <Route render={() => {
                    return <Redirect to={`${match.url}/comfort`} push={false} />
                }}/>
            </Switch>
        </div>
    )
}

Operation.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Operation)
