import React from 'react';

import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';

import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class ConfigZone extends React.Component {
  state = {
    spaces: this.props.spaces,
    selectedSpaceIds: this.props.zone.spaces.map(space=>space.id),
  }

  handleSelectedSpaceIdsChange = event => {
    this.setState({ selectedSpaceIds: event.target.value });
    this.props.updateZoneSpacesMutation({
      variables: {
        zoneId: this.props.zone.id,
        spaces: event.target.value.map(id => ({"id": id})),
      },
      refetchQueries: [{
        query: updateZoneCache,
        variables: {
          zoningId: this.props.zoningId,
        }
      }],
    }).then(({ data }) => {
      console.log('got data', data);
    }).catch((error) => {
      console.log('there was an error sending the query', error);
    });
  };

  handleZoneDelete = event => {
    this.props.deleteZoneMutation({
      variables: {
        zoneId: this.props.zone.id,
      },
      refetchQueries: [{
        query: updateZoneCache,
        variables: {
          zoningId: this.props.zoningId,
        }
      }],
    }).then(({ data }) => {
      console.log('got data', data);
    }).catch((error) => {
      console.log('there was an error sending the query', error);
    });
  }

  render() {
    return (
      <ExpansionPanel TransitionProps={{ unmountOnExit: true }}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" gutterBottom>{this.props.zone.displayName}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1" gutterBottom>Space(s)</Typography>
                </TableCell>
                <TableCell>
                </TableCell>
                <TableCell>
                  <Select
                    multiple
                    value={this.state.selectedSpaceIds}
                    onChange={this.handleSelectedSpaceIdsChange}
                    input={<Input id="spaces-multiple" />}
                    renderValue={selected => this.state.spaces.filter(space => selected.indexOf(space.id)>-1).map(space=>space.displayName).join(', ')}
                    MenuProps={MenuProps}
                  >
                    {this.state.spaces.map(space => (
                      <MenuItem key={space.id} value={space.id}>
                        <Checkbox checked={this.state.selectedSpaceIds.indexOf(space.id)>-1} />
                        <ListItemText primary={space.displayName} />
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </ExpansionPanelDetails>
        <Divider />
        <ExpansionPanelActions>
          <Button size="small" color="primary" onClick={this.handleZoneDelete} >
            Delete
          </Button>
        </ExpansionPanelActions>
      </ExpansionPanel>
    );
  }
}

const deleteZone = gql`
mutation deleteZone($zoneId: ID!) {
  deleteZone (
    id: $zoneId
  ) {
    id
  }
}`;

const updateZoneSpaces = gql`
mutation updateZoneSpaces($zoneId: ID!, $spaces: [SpaceWhereUniqueInput!]) {
  updateZone (
    id: $zoneId
    spaces: $spaces
  ) {
    id
    displayName
    spaces {
      id
      displayName
    }
  }
}`;

const updateZoneCache = gql`
query updateCache($zoningId: ID!) {
  zonings (id: $zoningId) {
    id
    displayName
    zones {
      id
      displayName
      spaces {
        id
        displayName
      }
    }
  }
}`;

export default compose(
  graphql(deleteZone, { name: 'deleteZoneMutation' }),
  graphql(updateZoneSpaces, {name : 'updateZoneSpacesMutation'}),
)(ConfigZone);
