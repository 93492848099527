import React from 'react'
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField'

/**
 * this is the "Year" dropdown used in several pages. 
 */
export const DropDownYear = props => {
  const {years} = props;
  return (
    <TextField select {...props}>
        {years.map(year => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
        ))}}
    </TextField>
  )
};

export default DropDownYear;