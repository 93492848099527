import React, { useEffect } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Divider from '@material-ui/core/Divider';
import { withRouter } from 'react-router-dom';

import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

const styles = theme => ({
    whiteTextField: {
        backgroundColor: '#FFFFFF',
        marginTop: '8px',
        marginBottom: '8px',
    },
    whiteMenu: {
    },
    whiteInput: {
        paddingLeft: '10px',
        backgroundColor: '#FFFFFF',
        hover: {
            height: '0px',
            backgroundColor: '#FFFFFF',
        }
    },
    whiteSelect: {
        backgroundColor: '#FFFFFF',
    },
});

const FormBuildingSelection = props => {
    const { classes, me, location, history, setParentBuilding } = props;
    const [ updateSelectedBuildingMutation ] = useMutation(updateSelectedBuilding);

    const handleSelectedBuildingIdChange = (projectID) => {
        const path = history.location.pathname;
        var path_array = path.split('/');
        if (path_array[1]==='buildings') {
            path_array.splice(2, 1, projectID);
            history.push(path_array.join('/'));
        } else {
            updateSelectedBuildingMutation({
                variables: {
                    userId: me.id,
                    projectID: projectID,
                },
                refetchQueries: [{
                    query: getCurrentUser,
                }],
            }).then(response => {
                history.push(path);
            })
        }
    }

    useEffect(() => {
        const urlSelectedProjectID = (location.pathname.startsWith("/buildings/") && location.pathname.split("/").length >= 3)
        ? location.pathname.split("/")[2]
        : null

        if (me && urlSelectedProjectID && (!me.selectedBuilding || (urlSelectedProjectID !== me.selectedBuilding.projectID))) {
            updateSelectedBuildingMutation({
                variables: {
                    userId: me.id,
                    projectID: urlSelectedProjectID,
                },
                refetchQueries: [{
                    query: getCurrentUser,
                }],
            }).then(response => {
                setParentBuilding(response.data.updateSelectedBuilding.selectedBuilding)
            })
        }
    }, [updateSelectedBuildingMutation, me, location, setParentBuilding])

    return (
        <div>
            {me ?
                <TextField
                    id="select-building"
                    select
                    value={me.selectedBuilding ? me.selectedBuilding.projectID : me.client.buildings[0].projectID}
                    onChange={(event) => handleSelectedBuildingIdChange(event.target.value)}
                    margin="normal"
                    className={classes.whiteTextField}
                    SelectProps={{
                        MenuProps: {
                            className: classes.whiteMenu,
                        },
                    }}
                    inputProps={{
                        className: classes.whiteInput,
                    }}
                    >
                    { me.client.buildings.sort((a, b) => a.displayName.localeCompare(b.displayName)).map(option => (
                        <MenuItem key={option.projectID} value={option.projectID}>
                            {option.displayName}
                        </MenuItem>
                    )) }

                    { me.client.buildings.length && me.externalBuildingRoles.length ? (
                        <Divider></Divider>
                    ) : null}

                    { me.externalBuildingRoles.sort((a, b) => a.building.displayName.localeCompare(b.building.displayName))
                                                .map(b => (
                                                    <MenuItem key={b.building.projectID} value={b.building.projectID}>
                                                    {b.building.displayName}
                                                    </MenuItem>
                                                ))
                    }
                </TextField>
                : <br/>
            }
        </div>
    )
}

const updateSelectedBuilding = gql`
mutation updateSelectedBuildingMutation($userId: ID!, $projectID: String!) {
    updateSelectedBuilding (userId: $userId projectID: $projectID) {
        id selectedBuilding { id projectID }
    }
}`

const getCurrentUser = gql`
query getCurrentUser {
    me {
        id name
        selectedBuilding {
            id projectID displayName
        }
    }
}`;

export default withStyles(styles)(withRouter(FormBuildingSelection));
