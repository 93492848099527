import React from 'react';

import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

const ResultsTabMethodology = props => {
    return (
      <div>
        <Paper style={{ padding: 25 }}>
            <Typography variant="h5">
                Savings calculation according to IPMVP 
            </Typography>
            <Typography variant="body1">
                &nbsp;
            </Typography>
            <Typography variant="body1">
                The  savings generated by DeltaQ are calculated according to the&nbsp;
                <a href="https://evo-world.org/en/products-services-mainmenu-en/protocols/ipmvp" target="_blank" rel="noopener noreferrer">
                    EVO® International Performance Measurement and Verification Protocol (IPMVP)
                </a>.
                <br/>
                Energy or demand savings cannot be directly measured, because savings represent the absence of energy consumption or demand. Instead, savings are determined by
                comparing measured consumption or demand before and after implementation of DeltaQ, making suitable adjustments for changes in conditions. The comparison of
                before and after energy consumption or demand is made on a consistent basis, using the Measurement &amp; Verification (M&amp;V) equation as defined in the IMPVMP
                protocol.
            </Typography>
            <Typography variant="body1">
                &nbsp;
            </Typography>
            <Typography variant="subtitle1">
                <b>Savings = (Baseline Period Energy – Reporting Period Energy) ± Adjustments</b>
            </Typography>
            <Typography variant="body1">
                <img alt="IPMVP Baseline Adjustment" src="/static/images/info-savings-calculation-chart.png" width="600" height="374" style={{"display": "block", "margin": "0px auto"}} />
            </Typography>
            <Typography variant="caption">
                © EVO
            </Typography>
            <Typography variant="body1">
                &nbsp;
            </Typography>
            <Typography variant="h5">
                Terms &amp; Definitions
            </Typography>
            <Typography variant="body1">
                &nbsp;
            </Typography>
            <Typography variant="h6">
                Baseline
            </Typography>
            <Typography variant="body1">
                Referring to the systems, time period, energy use or conditions that provide a reference to which later performance of DeltaQ can be compared.
                <br/>
                The Baseline can be corrected for DeltaQ independent energy consumption and/or in case of no or non-consistent data during specific periods in time.
            </Typography>
            <Typography variant="h6">
                <br/>
                Reporting Period
            </Typography>
            <Typography variant="body1">
                Defined period of time for the purposes of verifying savings after implementation of DeltaQ
            </Typography>
            <Typography variant="h6">
                <br/>
                Adjusted Baseline (Energy)
            </Typography>
            <Typography variant="body1">
                The Baseline Period energy consumption modified as part of routine and non-routine adjustments to account for changes in the Reporting Period.
                The Adjusted Baseline Energy is found by developing a mathematical model that correlates actual baseline period energy data with appropriate
                independent variables in the baseline period. Each reporting period’s independent variables are then inserted into this baseline mathematical
                model to produce the Adjusted Baseline Energy.
            </Typography>
            <Typography variant="body1">
                A few examples of independent variables:
            </Typography>
            <ul>
                <li>Different heating/cooling degree day definitions</li>
                <li>Solar irradiation</li>
                <li>Wind speed and direction</li>
                <li>Type of day (work day – weekend)</li>
            </ul>
            <Typography variant="h6">
                Savings
            </Typography>
            <Typography variant="body1">
                The DeltaQ Savings are calculated as the difference between the Adjusted Baseline Energy and the Reporting Period Energy (in kWh).
            </Typography>
            <Typography variant="body1">
                &nbsp;
            </Typography>
            <Typography variant="h5">
                Full list of potential independent variables
            </Typography>
            <Typography variant="body1">
                &nbsp;
            </Typography>
            <Typography variant="h6">
                Cooling degree days
            </Typography>
            <ul>
                <li>CDD_base4</li>
                <li>CDD_base6</li>
                <li>CDD_base8</li>
                <li>CDD_base10</li>
                <li>CDD_base12</li>
                <li>CDD_base14</li>
                <li>CDD_base16</li>
                <li>CDD_base18</li>
                <li>CDD_base20</li>
                <li>CDD_base22</li>
                <li>CDD_base24</li>
            </ul>
            <Typography variant="h6">
                Heating degree days
            </Typography>
            <ul>
                <li>HDD_base0</li>
                <li>HDD_base2</li>
                <li>HDD_base4</li>
                <li>HDD_base6</li>
                <li>HDD_base8</li>
                <li>HDD_base10</li>
                <li>HDD_base12</li>
                <li>HDD_base14</li>
                <li>HDD_base16</li>
                <li>HDD_base18</li>
                <li>HDD_base20</li>
                <li>HDD_base22</li>
                <li>HDD_base24</li>
                <li>HDD_base26</li>
            </ul>
            <Typography variant="h6">
                Irradiance
            </Typography>
            <ul>
                <li>irradiance</li>
                <li>irradiance_A000I090</li>
                <li>irradiance_A090I090</li>
                <li>irradiance_A180I090</li>
                <li>irradiance_A270I090</li>
                <li>irradiance_diffuse</li>
                <li>irradiance_direct_normal</li>
            </ul>
            <Typography variant="h6">
                Other
            </Typography>
            <ul>
                <li>workdays</li>
            </ul>
        </Paper>
      </div>
    );
}

const ResultsTabMethodologyQuery = gql`
query TabProfileQuery ($projectID: String!) {
  profileDayTypes (projectID: $projectID) {
    id
    displayName
  }
}`;

export default graphql(ResultsTabMethodologyQuery, {
  options: (props) => ({ variables: { projectID: props.match.params.projectID } })
})(ResultsTabMethodology);
