import React from 'react'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'

import ChartHistoryWaterCircuits from './ChartHistoryWaterCircuits';

const OperationCircuit = props => {
  // console.log(props.match)
  const { projectID } = props.match.params
  return (
    <Paper style={{ padding: 25 }}>
      <Typography variant="subtitle1">Water Circuits</Typography>
      <Typography>&nbsp;</Typography>
      <Grid container>
        <Grid item xs={12}>
          <ChartHistoryWaterCircuits
            projectID={projectID}
            systemMetricGroups={[
              {
                "systemTemplateName": "systemTemplate_wc",
                "systemMetricTemplateName": "wc_temperature_water_supply",
                "systemMetricTagFunction": "sensor",
                "axis": "temperature_water",
                "color": "#649E2A",
                "addToLegend": true,
              },
              {
                "systemTemplateName": "systemTemplate_wc",
                "systemMetricTemplateName": "wc_temperature_water_supply",
                "systemMetricTagFunction": "setpoint",
                "systemMetricTagRwc": "read",
                "axis": "temperature_water",
                "color": "#649E2A",
                "dashed": true,
                "addToLegend": true,
              },
              {
                "systemTemplateName": "systemTemplate_wc",
                "systemMetricTemplateName": "wc_temperature_water_return",
                "systemMetricTagFunction": "sensor",
                "axis": "temperature_water",
                "color": "#9BCB6A",
                "addToLegend": true,
              },
              {
                "systemTemplateName": "systemTemplate_wc",
                "systemMetricTemplateName": "wc_temperature_water_return",
                "systemMetricTagFunction": "setpoint",
                "systemMetricTagRwc": "read",
                "axis": "temperature_water",
                "color": "#9BCB6A",
                "dashed": true,
                "addToLegend": true,
              },
              {
                "systemTemplateName": "systemTemplate_wc",
                "systemMetricTemplateName": "wc_power_thermal",
                "systemMetricTagFunction": "sensor",
                "axis": "power_thermal",
                "color": "#575AB6",
                "addToLegend": true,
              },
              {
                "systemTemplateName": "systemTemplate_wc",
                "systemMetricTemplateName": "wc_power_thermal",
                "systemMetricTagFunction": "setpoint",
                "systemMetricTagRwc": "read",
                "axis": "power_thermal",
                "color": "#575AB6",
                "dashed": true,
                "addToLegend": true,
              },
              {
                "systemTemplateName": "systemTemplate_wc",
                "systemMetricTemplateName": "wc_binary_state_pump",
                "systemMetricTagFunction": "sensor",
                "axis": "status",
                "color": "#763F08",
                "addToLegend": true,
              },
              {
                "systemTemplateName": "systemTemplate_wc",
                "systemMetricTemplateName": "wc_binary_state_pump",
                "systemMetricTagFunction": "setpoint",
                "systemMetricTagRwc": "read",
                "axis": "status",
                "color": "#763F08",
                "dashed": true,
                "addToLegend": true,
              },
              {
                "systemTemplateName": "systemTemplate_wc",
                "systemMetricTemplateName": "wc_offmanauto_state",
                "systemMetricTagFunction": "sensor",
                "axis": "status",
                "color": "#763F08",
                "addToLegend": true,
              },
              {
                "systemTemplateName": "systemTemplate_wc",
                "systemMetricTemplateName": "wc_offmanauto_state",
                "systemMetricTagFunction": "setpoint",
                "systemMetricTagRwc": "read",
                "axis": "status",
                "color": "#763F08",
                "dashed": true,
                "addToLegend": true,
              },
            ]}
            systemMetricAxes={[
              {
                "name": "temperature_water",
                "label": "Water temperature [°C]",
              },
              {
                "name": "power_thermal",
                "label": "Thermal power [kW]",
              },
              {
                "name": "status",
                "label": "Status",
              },
            ]}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default OperationCircuit
