import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks'
import { ApolloClient } from 'apollo-client';
import { getMainDefinition } from 'apollo-utilities';
import { split } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { WebSocketLink } from 'apollo-link-ws';
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory';
//import { CachePersistor } from 'apollo-cache-persist';

import { createUploadLink } from 'apollo-upload-client';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import './index.css';
import {unregister} from './registerServiceWorker';

import TemplateLanding from './TemplateLanding';
import TemplateAuth from './TemplateAuth';
import TemplateMenu from './TemplateMenu';

import AppSettings from './AppSettings';

import CurrentUserProvider from './contexts/CurrentUser';

import { WebAuth }  from 'auth0-js';

Sentry.init({dsn: "https://323dae9369bb46beb7e0acf6c460dd90@sentry.io/3463799"});

const graphQLUri = AppSettings.GraphQLUri;

const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'cache-first',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'cache-first',
        errorPolicy: 'ignore',
    },
    mutation: {
        fetchPolicy: 'cache-first',
        errorPolicy: 'ignore',
    },
}

var webAuth = new WebAuth({
  domain: AppSettings.GraphQLAuthDomain,
  clientID: AppSettings.GraphQLAuthClientID,
  redirectUri: window.location.protocol + '//' + window.location.host + '/auth_callback',
  audience: AppSettings.GraphQLAuthAudience,
  responseType: 'token id_token'
});

const Main = () => {
    const [isValidSession, setIsValidSession] = useState(false)
    const [client, setClient] = useState(false)

    const setSession = (authResult) => {
        const expiresAt = JSON.stringify(
            authResult.expiresIn * 1000 + new Date().getTime()
        )
        localStorage.setItem('sqb_auth0_access_token', authResult.accessToken)
        localStorage.setItem('sqb_auth0_id_token', authResult.idToken)
        localStorage.setItem('sqb_auth0_expires_at', expiresAt)
        scheduleRenewal();
    }

    const renewToken = () => {
        webAuth.checkSession({},
            (err, result) => {
                console.log('check session err: ', err)
                console.log('check session result:', result)
                if (err) {
                    setIsValidSession(false)
                    if (!((window.location.href === window.location.origin + "/") && (window.location.pathname === "/"))) {
                        webAuth.authorize({prompt: "login"});
                    }
                } else {
                    setSession(result);
                    setIsValidSession(true)
                }
            }
        );
    }

    const scheduleRenewal = () => {
        const expiresAt = JSON.parse(localStorage.getItem('sqb_auth0_expires_at'));
        const delay = expiresAt - Date.now();
        const delta = delay - 60000;
        console.log(`session renewal delta: ${delta} | delay: ${delay}`)
        if (delay > 0) {
            setIsValidSession(true)
            setTimeout(() => {
                renewToken();
            }, delta);
        } else {
            renewToken();
        }
    }

    useEffect(() => {
        scheduleRenewal()
    })

    // if (!isValidSession) {
    //     console.log("session query")
    //     return <></>
    // }

    // persistCacheSync instead of "await persistCache"
    // persistCacheSync({
    // const persistor = new CachePersistor({
    //     cache,
    //     storage: window.localStorage,
    // });
    // persistor.restore().then(() => {
    // persistor.getSize().then((size) => {console.log("persistor size: " + size)})

    if (!client) {
        const wsLink = new WebSocketLink({
            uri: graphQLUri.replace('http', 'ws'),
            options: {
                reconnect: true,
                timeout: 30000,
                connectionParams: () => (
                    { Authorization: localStorage.getItem('sqb_auth0_access_token')
                    ? `Bearer ${localStorage.getItem('sqb_auth0_access_token')}`
                    : "" }
                )
            }
        })

        const uploadLink = createUploadLink({
            uri: graphQLUri
        })

        const authLink = setContext((_, { headers }) => ({
            headers: {
                ...headers,
                authorization: localStorage.getItem('sqb_auth0_access_token')
                ? `Bearer ${localStorage.getItem('sqb_auth0_access_token')}`
                : ""
            }
        }))

        const link = split(
            ({ query }) => {
                const { kind, operation } = getMainDefinition(query)
                return kind === 'OperationDefinition' && operation === 'subscription'
            },
            wsLink,
            authLink.concat(uploadLink)
        )

        /* https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-config-options-fetchPolicy */
        /* fetchPolicy: cache-first (default) | cache-and-network | network-only | cache-only */
        /* errorPolicy: none (default) | ignore | all */

        const cache = new InMemoryCache({
            dataIdFromObject: object => {
                switch (object.__typename) {
                    case 'Building': return `Building:${object.projectID}`;
                    default: return defaultDataIdFromObject(object);        }
            }
        });

        console.log("creating client")

        setClient(new ApolloClient({
            cache,
            defaultOptions,
            link,
            shouldBatch: true,
        }))
    }

    if (!(isValidSession && client)) {
        return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <ApolloProvider client={client}>
                    <Router>
                        <Switch>
                            <Route exact path="/" component={TemplateLanding} />
                            <Route exact path="/auth_callback" component={TemplateAuth} />
                        </Switch>
                    </Router>
                </ApolloProvider>
            </MuiPickersUtilsProvider>
        )
    }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <ApolloProvider client={client}>
                <Router>
                    <Switch>
                        <Route exact path="/" component={TemplateLanding} />
                        <Route exact path="/auth_callback" component={TemplateAuth} />
                        <CurrentUserProvider>
                            <Route exact path="/:page" component={TemplateMenu} />
                            <Route path="/buildings/:projectID/:page" component={TemplateMenu} />
                        </CurrentUserProvider>
                    </Switch>
                </Router>
            </ApolloProvider>
        </MuiPickersUtilsProvider>
    )
}

ReactDOM.render(<Main/>, document.getElementById('root'))

unregister()
