import React from 'react'

import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Grid from '@material-ui/core/Grid';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import Typography from '@material-ui/core/Typography';
import LinkIcon from '@material-ui/icons/Link';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { CurrentUserContext } from '../contexts/CurrentUser'

const getBackgoundColor = (level, client) => (
  level==="ok"
  ? client.colorStateOk
  : level==="info"
  ? client.colorStateOk
  : level==="warning"
  ? client.colorStateWarning
  : level==="error"
  ? client.colorStateError
  : client.colorStateInactive
)

const ProjectOverviewBuildingStates = props => {
  return (
    <CurrentUserContext.Consumer>{ me => {
      const buildingStates = (props.data.error || props.data.loading || !props.data.me )
      ? []
      : props.data.me.client.buildings.reduce(
        (sl, b) => (sl.concat(b.buildingStates.map(bs => bs).filter(ss => sl.findIndex(s => (s.title === ss.title)) < 0))),
        [])
      
      buildingStates.sort((a, b) => (
        a.category === undefined
        ? b.category === undefined
        ? -1
        : 1
        : a.category.localeCompare(b.category) === 0
        ? a.title.localeCompare(b.title)
        : a.category.localeCompare(b.category)
      ))

      return (
        (props.data.error || props.data.loading || !props.data.me ) ? (
          <Typography></Typography>
        ) : (
          <Grid container spacing={4} hidden={!(me.role === "OPERATOR" || me.role === "ADMIN" || me.role === "SUPERADMIN")}>
            <Grid item xs={12}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell key="projectID">Project</TableCell>
                    <TableCell key="link"></TableCell>
                    {buildingStates.map(bs => (
                      <TableCell key={bs.id} align="center">{bs.title}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.data.me.client.buildings
                  .sort((a, b) => a.projectID.localeCompare(b.projectID))
                  .map(building => (
                    <TableRow key={building.id}>
                      <TableCell key="projectID">{building.projectID}</TableCell>
                      <TableCell key="link" align="center"><a href={"/buildings/"+ building.projectID +"/state"}><LinkIcon color="primary" /></a></TableCell>
                      {buildingStates.map(bs => (
                        building.buildingStates.findIndex(bbs => bbs.title === bs.title) >= 0 ? (
                          <TableCell key={bs.id} align="center" title={building.buildingStates.find(bbs => bbs.title === bs.title).message.replace(/ ; /gi, "\n")}>{building.buildingStates.find(bbs => bbs.title === bs.title).alertValidated ? <CheckCircleOutlineIcon style={{ color: getBackgoundColor(building.buildingStates.find(bbs => bbs.title === bs.title).level, props.data.me.client)}} /> : <RadioButtonCheckedIcon style={{ color: getBackgoundColor(building.buildingStates.find(bbs => bbs.title === bs.title).level, props.data.me.client)}} />}</TableCell>
                        ) : (
                          <TableCell key={bs.id} align="center"><RadioButtonUncheckedIcon style={{ color: getBackgoundColor("inactive", props.data.me.client) }} /></TableCell>
                        )
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        )
      )
    }}
    </CurrentUserContext.Consumer>
  )
}

const getCurrentUser = gql`
query getCurrentUser {
  me {
    id
    email
    name
    role
    externalBuildingRoles {
      building {
        id
        projectID
        displayName
        buildingStates {
          id
          title
          category
          level
          message
          alertValidated
          createdAt
          updatedAt
        }
      }
    }
    selectedBuilding {
      id
      projectID
      displayName
      lastDataPushDateTime
      image {
        id
        file {
            id
            url
        }
      }
    }
    client {
      id
      displayName
      colorStateOk
      colorStateWarning
      colorStateError
      colorStateInactive
      logo {
        id
        file {
          id
          url
        }
      }
      buildings {
        id
        projectID
        displayName
        buildingStates {
          id
          title
          category
          level
          message
          alertValidated
          createdAt
          updatedAt
        }
      }
      users {
        id
        name
        email
        role
      }
    }
  }
}`

export default graphql(getCurrentUser)(ProjectOverviewBuildingStates);
