import React, { useState, useEffect } from 'react'
import withStyles from '@material-ui/core/styles/withStyles';

import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';

import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch'

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import * as d3 from 'd3-time'
import moment, { now } from 'moment'
import { TimeSeries, TimeRange } from "pondjs"

import 'react-vis/dist/style.css'
import {FlexibleWidthXYPlot, XAxis, HorizontalGridLines, LineMarkSeries, LineSeries, HorizontalRectSeries, Crosshair, Highlight} from 'react-vis';

const COLOR_HISTORY = "#669966"
const COLOR_FORECAST = "#660099"

const styles = theme => ({
  centeredModal: {
    position: 'absolute',
    width: theme.spacing(60),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
});

const getTimeseriesFromPayload = (id, name, payload, timeRange, invertedMap) => (
  (payload == null) || (timeRange == null)
  ? new TimeSeries({
    name: name,
    columns: ["time", id],
    points: []
  })
  : new TimeSeries({
      name: name,
      columns: ["time", id],
      points: payload
//          .filter(e => parseFloat(e[0]) >= timeRange.begin().getTime()/1000 && parseFloat(e[0]) <= timeRange.end().getTime()/1000)
          .sort()
          .map(e => [ e[0]*1000, invertedMap.get(e[1]) ? invertedMap.get(e[1]) : e[1] === "null" ? null : e[1] ])
  }).clean(id)
)
    //.filter(e => Number.isFinite(e[1]))

const getXYListFromPayload = (id, name, payload, timeRange, invertedMap) => (
  (payload == null) || (timeRange == null)
  ? []
  : payload
//    .filter(e => parseFloat(e[0]) >= timeRange.begin().getTime()/1000 && parseFloat(e[0]) <= timeRange.end().getTime()/1000)
    .sort()
    .map(e => ({ x: new Date(e[0]*1000), y: invertedMap.get(e[1]) ? invertedMap.get(e[1]) : e[1] === "null" ? null : e[1] }))
)

const getColorFromEventText = (text) => (
  text.split(".")[1] === "error"
  ? "#CC9999"
  : text.split(".")[0] === "clear"
  ? "#AAAAAA"
  : "#99CC99"
)

const getRectListFromPayload = (id, name, payload, timeRange) => {
  const events = (payload == null) || (timeRange == null)
  ? []
  : payload
//    .filter(e => parseFloat(e[0]) >= timeRange.begin().getTime()/1000 && parseFloat(e[0]) <= timeRange.end().getTime()/1000)
    .sort()
    .map(e => ({ x: new Date(e[0]*1000), color: getColorFromEventText(e[1]) }))
  return events
    .slice(0,-1)
    .map((e, i) => ({x: e.x, x0: events[i+1].x, y: 0, y0: 1, color:e.color}))
}

const getTimeRange = dateTime => (
  new TimeRange([moment(dateTime).subtract(3, 'days').toDate(), moment(dateTime).toDate()])
)

const getFutureTimeRange = dateTime => (
  new TimeRange([moment(dateTime).toDate(), moment(dateTime).add(2, 'days').toDate()])
)

const OperationControlOld = props => {
  const { classes, projectID } = props;
  
  const [modelSignals, setModelSignals] = useState([])
  const [chartXDomain, setChartXDomain] = useState([getTimeRange(moment().toDate()).begin(), getFutureTimeRange(moment().toDate()).end()]);
  const [crosshairValues, setCrosshairValues] = useState([]);
  const [selectedX, setSelectedX] = useState(null);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalControlStatusSetpoint, setModalControlStatusSetpoint] = useState("Unknown");
  const [modalControlModelSignal, setModalControlModelSignal] = useState(null);
  const [modalControlContext, setModalControlContext] = useState("");
  const [lastEnableContext, setLastEnableContext] = useState("");
  const [lastDisableContext, setLastDisableContext] = useState("");
  const [modalLastEnableTime, setModalLastEnableTime] = useState(moment(now()).subtract(10, 'minutes'));
  const [modalLastDisableTime, setModalLastDisableTime] = useState(moment(now()).subtract(10, 'minutes'));

  const building = props.data.me && props.data.me.client.buildings.length > 0
  ? props.data.me.client.buildings[0]
  : props.data.me && props.data.me.externalBuildingRoles.length > 0
  ? props.data.me.externalBuildingRoles[0].building
  : null

  const activeControl = building ? building.activeControl : null

  const getModelSignalsFromControl = (control) => {
    var tagNames = control.controlVector.payload.ControlTrajectories.map(ct => Object.keys(ct)[0])
    tagNames.sort((a,b) => a.split("#").reverse()[0].localeCompare(b.split("#").reverse()[0]))
    
    const modelSignals = tagNames.map(tagName => {
      const shortTagName = tagName.split("#").reverse()[0];
      const valueMap = control.metaData[tagName] && control.metaData[tagName].valueMap ? control.metaData[tagName].valueMap : {};
      const invertedMap = new Map(Array.from(Object.entries(valueMap), a => a.reverse()));
      const trHistory = getTimeRange(moment().toDate())
      const tsCurrent = control.controlHistory && control.controlHistory.lastControlValues && control.controlHistory.lastControlValues.ControlTrajectories.length > 0
      && control.controlHistory.lastControlValues.ControlTrajectories.find(ct=>Object.keys(ct)[0]===tagName)
      ? getTimeseriesFromPayload("data", "data", control.controlHistory.lastControlValues.ControlTrajectories.find(ct=>Object.keys(ct)[0]===tagName)[tagName], trHistory, invertedMap)
      : null
      const xyCurrent = control.controlHistory && control.controlHistory.lastControlValues && control.controlHistory.lastControlValues.ControlTrajectories.length > 0
      && control.controlHistory.lastControlValues.ControlTrajectories.find(ct=>Object.keys(ct)[0]===tagName)
      ? getXYListFromPayload("data", "data", control.controlHistory.lastControlValues.ControlTrajectories.find(ct=>Object.keys(ct)[0]===tagName)[tagName], trHistory, invertedMap)
      : null
      const tsHistory = control.controlHistory && control.controlHistory.controlValues && control.controlHistory.controlValues.ControlTrajectories.length > 0
      && control.controlHistory.controlValues.ControlTrajectories.find(ct=>Object.keys(ct)[0]===tagName)
      ? getTimeseriesFromPayload("data", "data", control.controlHistory.controlValues.ControlTrajectories.find(ct=>Object.keys(ct)[0]===tagName)[tagName], trHistory, invertedMap)
      : null
      const xyHistory = control.controlHistory && control.controlHistory.controlValues && control.controlHistory.controlValues.ControlTrajectories.length > 0
      && control.controlHistory.controlValues.ControlTrajectories.find(ct=>Object.keys(ct)[0]===tagName)
      ? getXYListFromPayload("data", "data", control.controlHistory.controlValues.ControlTrajectories.find(ct=>Object.keys(ct)[0]===tagName)[tagName], trHistory, invertedMap)
      : null
      const rectHistory = control.controlHistory && control.controlHistory.pointStates && control.controlHistory.pointStates.ControlTrajectories.length > 0
      && control.controlHistory.pointStates.ControlTrajectories.find(ct=>Object.keys(ct)[0]===tagName)
      ? getRectListFromPayload("data", "data", control.controlHistory.pointStates.ControlTrajectories.find(ct=>Object.keys(ct)[0]===tagName)[tagName], trHistory)
      : null
      const trVector = getFutureTimeRange(moment().toDate())
      const tsVector = control.controlVector && control.controlVector.payload && control.controlVector.payload.ControlTrajectories.length > 0
      && control.controlVector.payload.ControlTrajectories.find(ct=>Object.keys(ct)[0]===tagName)
      ? getTimeseriesFromPayload("data", "data", control.controlVector.payload.ControlTrajectories.find(ct=>Object.keys(ct)[0]===tagName)[tagName], trVector, invertedMap)
      : null
      const xyVector = control.controlVector && control.controlVector.payload && control.controlVector.payload.ControlTrajectories.length > 0
      && control.controlVector.payload.ControlTrajectories.find(ct=>Object.keys(ct)[0]===tagName)
      ? getXYListFromPayload("data", "data", control.controlVector.payload.ControlTrajectories.find(ct=>Object.keys(ct)[0]===tagName)[tagName], trVector, invertedMap)
      : null
      return {
        id: tagName,
        name: shortTagName,
        signals: [{
          id: tagName,
          name: shortTagName,
          comment: control.metaData[tagName] ? control.metaData[tagName].displayName : shortTagName,
          tooltip: control.metaData[tagName] ? control.metaData[tagName].description : shortTagName,
          tsCurrent: tsCurrent,
          xyCurrent: xyCurrent,
          trHistory: trHistory,
          tsHistory: tsHistory,
          xyHistory: xyHistory,
          rectHistory: rectHistory,
          trVector: trVector,
          tsVector: tsVector,
          xyVector: xyVector,
          steerMode: control.metaData[tagName] ? control.metaData[tagName].steerMode ? control.metaData[tagName].steerMode : "" : "",
          valueMap: valueMap,
          userInput: null,
        }]
      }
    })

    return modelSignals
  }

  useEffect(() => {
    if (activeControl) {
      setModelSignals(getModelSignalsFromControl(activeControl))
    }
  }, [activeControl])

  const handleSteerModeChange = (ms_id, event, checked) => {
    const statusSetpoint = checked ? "Active" : "Inactive";
    const modelSignal = ms_id;
    setModalControlStatusSetpoint(statusSetpoint);
    setModalControlModelSignal(modelSignal);
    if (statusSetpoint==="Active" && (modalLastEnableTime > moment(now()).subtract(5, 'minutes'))) {
      setModalLastEnableTime(moment(now()));
      updateSetpoint(modelSignal, statusSetpoint, lastEnableContext);
    } else if (statusSetpoint==="Inactive" && (modalLastDisableTime > moment(now()).subtract(5, 'minutes'))) {
      setModalLastDisableTime(moment(now()));
      updateSetpoint(modelSignal, statusSetpoint, lastDisableContext);
    } else {
      setModalOpen(true);
    }
  };

  const handleModalControlContextChange = (event) => {
    setModalControlContext(event.target.value)
    if (modalControlStatusSetpoint==="Active") {
      setLastEnableContext(event.target.value)
    } else {
      setLastDisableContext(event.target.value)
    }
  }
  const handleModalControlCancel = (event) => {
    setModalControlStatusSetpoint("Unknown");
    setModalControlModelSignal(null);
    setModalOpen(false);
    setModalControlContext("");
  };
  const handleModalControlConfirm = (event) => {
    if (modalControlStatusSetpoint==="Active") {
      setModalLastEnableTime(moment(now()));
      updateSetpoint(modalControlModelSignal, "Active", lastEnableContext);
    } else if (modalControlStatusSetpoint==="Inactive" && modalControlContext.length>=2) {
      setModalLastDisableTime(moment(now()));
      updateSetpoint(modalControlModelSignal, "Inactive", lastDisableContext);
    }
  };
  const updateSetpoint = (modelSignal, statusSetpoint, context) => {
    props.createLogEntryMutation({
      variables: {
        projectID: projectID,
        name: props.data.me ? props.data.me.name + (props.data.me.client ? " (" + props.data.me.client.displayName + ")" : "") : "",
        description: "DeltaQ " + (statusSetpoint==="Active" ? "Enabled" : "Disabled") + " on " + modelSignal + " - " + context,
        start: moment(now()),
        end: moment(now()),
      }
    }).then(response => {
      var control = JSON.parse(JSON.stringify(activeControl));
      control.metaData[modelSignal].steerMode = (statusSetpoint==="Active") ? "control_vector" : "clear";
  
      props.updateControlMetaDataMutation({
        variables: {
          id: activeControl.id,
          metaData: control.metaData,
        },
        refetchQueries: [{
          query: updateBuildingControlCache,
          variables: { projectID }
        }],
      }).then(response => {
        setModelSignals(getModelSignalsFromControl(control))
        setModalControlStatusSetpoint("Unknown")
        setModalControlModelSignal(null)
        setModalOpen(false)
        setModalControlContext("");
      })
    })
  }

  if (props.data.error) {
    return (<Paper style={{ padding: 25 }}><Grid container><Grid item style={{height:"200px"}}><Typography gutterBottom>Error fetching chart data!</Typography></Grid></Grid></Paper>)
  };
  if (props.data.loading) {
    return (<Paper style={{ padding: 25 }}><Grid container><Grid item style={{height:"200px"}}><CircularProgress size={50} color="secondary" /></Grid></Grid></Paper>)
  };
  if (!props.data.me || (props.data.me.client.buildings.length === 0 && props.data.me.externalBuildingRoles.length === 0) ) {
    return (<Paper style={{ padding: 25 }}><Grid container><Grid item style={{height:"200px"}}><Typography gutterBottom>No data available</Typography></Grid></Grid></Paper>)
  } else {
    return (
      <div>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={modalOpen}
        >
          <div className={classes.centeredModal}>
            <Typography variant="h5" id="modal-title">
              {
                modalControlStatusSetpoint==="Active"
                ? "Start DeltaQ Control"
                : "Stop DeltaQ Control?"
              }
            </Typography>
            <br/>
            <Typography variant="body1" id="simple-modal-description">
              {
                modalControlStatusSetpoint==="Active"
                ? ""
                : "Revert to scheduled or manual control. This event is logged for quality analysis purposes, please describe the reason for shutdown."
              }
            </Typography>
            <br/>
            <TextField
              id="simple-modal-context"
              label={modalControlStatusSetpoint==="Active"
                ? "Log message [optional]"
                : "Cause for disabling DeltaQ [required]"
              }
              error={modalControlStatusSetpoint!=="Active" && modalControlContext.length<2}
              multiline
              rows="4"
              style={{minWidth:"80%"}}
              defaultValue={modalControlContext}
              variant="outlined"
              onChange={handleModalControlContextChange}
            />
            <br/>
            <br/>
            <Button variant="contained" color="primary" onClick={handleModalControlCancel}>Cancel</Button>
            &nbsp;&nbsp;&nbsp;
            <Button variant="contained" color="primary" onClick={handleModalControlConfirm}>Confirm</Button>
          </div>
        </Modal>
        <Paper style={{ padding: 25 }} key={"header"}>
          <Grid container>
            <Grid item key={"header_n"} xs={3} sm={3} md={3} lg={3} xl={3}>
              <Typography variant="subtitle1" style={{textAlign: "center"}}>BMS&nbsp;Tags</Typography>
            </Grid>
            <Grid item key={"header_l"} xs={1} sm={1} md={1} lg={1} xl={1}>
              <Box textAlign="center" m={0}>Last&nbsp;Control</Box>
              <Box textAlign="center" m={0}>
                <Typography variant="caption" style={{textAlign: "center"}}>
                  {building.activeControl && building.activeControl.controlHistory ? moment(building.activeControl.controlHistory.lastControlValues.timestamp * 1000).format('YYYY-MM-DD HH:mm') : ''}
                </Typography>
              </Box>
            </Grid>
            <Grid item key={"header_sel"} xs={1} sm={1} md={1} lg={1} xl={1} style={{color:(selectedX ? "#FFFFFF" : null), backgroundColor:(selectedX ? (selectedX > new Date()) ? COLOR_FORECAST : COLOR_HISTORY : null)}}>
              <Box textAlign="center" m={0}>Selected</Box>
              <Box textAlign="center" m={0}>
                <Typography variant="caption" style={{textAlign: "center"}}>
                  {selectedX ? moment(selectedX).format('YYYY-MM-DD HH:mm') : " "}
                </Typography>
              </Box>
            </Grid>
            <Grid item key={"header_m"} xs={1} sm={1} md={1} lg={1} xl={1}>
              <Box textAlign="right" m={0}>ValueMap</Box>
            </Grid>
            <Grid item key={"header_h"} xs={3} sm={3} md={3} lg={3} xl={3}>
              <Box textAlign="center" m={0}>Control&nbsp;History</Box>
              <Box textAlign="center" m={0}>
                <Typography variant="caption" style={{textAlign: "center"}}>
                  Updated: {building.activeControl && building.activeControl.controlHistory ? moment(building.activeControl.controlHistory.controlValues.timestamp * 1000).format('YYYY-MM-DD HH:mm') : ''}
                </Typography>
              </Box>
            </Grid>
            <Grid item key={"header_v"} xs={3} sm={3} md={3} lg={3} xl={3}>
              <Box textAlign="center" m={0}>Planned&nbsp;Control</Box>
              <Box textAlign="center" m={0}>
                <Typography variant="caption" style={{textAlign: "center"}}>
                  Calculated: {building.activeControl && building.activeControl.controlVector ? moment(building.activeControl.controlVector.payload.timestamp * 1000).format('YYYY-MM-DD HH:mm') : ''}
                </Typography>
              </Box>
            </Grid>
          {modelSignals.map(m => (
              m.signals
                .map(ms => {
                  return([
                    <Grid item key={ms.id+"_s"} xs={1} sm={1} md={1} lg={1} xl={1}>
                      <Switch checked={ms.steerMode==="control_vector"} onChange={(event, checked) => handleSteerModeChange(ms.id, event, checked)} />
                    </Grid>,
                    <Grid item key={ms.id+"_n"} xs={2} sm={2} md={2} lg={2} xl={2} style={{padding:"3px", color:(ms.steerMode==="control_vector" ? "#FFFFFF" : null), backgroundColor:(ms.steerMode==="control_vector" ? COLOR_HISTORY : null)}}>
                      <Tooltip id="tooltip-name" title={ms.tooltip + " [" + ms.id + "]"} placement="bottom-start">
                        <Typography variant="body2"><b>{ms.comment ? ms.comment : ""}</b></Typography>
                      </Tooltip>
                    </Grid>,
                    <Grid item key={ms.id+"_l"} xs={1} sm={1} md={1} lg={1} xl={1} style={{padding:"3px", color:(ms.steerMode==="control_vector" ? "#FFFFFF" : null), backgroundColor:(ms.steerMode==="control_vector" ? COLOR_HISTORY : null)}}>
                      <Typography style={{textAlign: "center"}}>{
                        ms.tsCurrent === null || ms.tsCurrent === undefined || ms.tsCurrent.atLast() === undefined
                        ? ""
                        : ms.tsCurrent.atLast().get("data") === "null"
                        ? "Auto"
                        : ms.tsCurrent.atLast().get("data") in ms.valueMap
                        ? ms.valueMap[ms.tsCurrent.atLast().get("data")]
                        : ms.tsCurrent.atLast().get("data")
                      }</Typography>
                    </Grid>,
                    <Grid item key={ms.id+"_sel"} xs={1} sm={1} md={1} lg={1} xl={1} style={{padding:"3px", color:(selectedX ? "#FFFFFF" : null), backgroundColor:(selectedX ? (selectedX > new Date()) ? COLOR_FORECAST : COLOR_HISTORY : null)}}>
                      <Typography style={{textAlign: "center"}}>{
                        selectedX ? selectedX > new Date() ? (
                          ms.xyVector === null || ms.xyVector === undefined || ms.xyVector.length === 0 || ms.xyVector.filter(v => v.x <= selectedX).length === 0
                          ? ""
                          : ms.xyVector.filter(v => v.x <= selectedX).reverse()[0].y === "null"
                          ? "Auto"
                          : ms.xyVector.filter(v => v.x <= selectedX).reverse()[0].y in ms.valueMap
                          ? ms.valueMap[ms.xyVector.filter(v => v.x <= selectedX).reverse()[0].y]
                          : ms.xyVector.filter(v => v.x <= selectedX).reverse()[0].y
                        ) : (
                          ms.xyHistory === null || ms.xyHistory === undefined || ms.xyHistory.length === 0 || ms.xyHistory.filter(v => v.x <= selectedX).length === 0
                          ? ""
                          : ms.xyHistory.filter(v => v.x <= selectedX).reverse()[0].y === "null"
                          ? "Auto"
                          : ms.xyHistory.filter(v => v.x <= selectedX).reverse()[0].y in ms.valueMap
                          ? ms.valueMap[ms.xyHistory.filter(v => v.x <= selectedX).reverse()[0].y]
                          : ms.xyHistory.filter(v => v.x <= selectedX).reverse()[0].y
                        ) : " "
                      }</Typography>
                    </Grid>,
                    <Grid item key={ms.id+"_m"} xs={1} sm={1} md={1} lg={1} xl={1} style={{padding:"3px"}}>
                      <Typography variant="caption" style={{textAlign: "right"}}>{
                        ms.valueMap
                        ? Object.keys(ms.valueMap)
                            .sort()
                            .reverse()
                            .map(k => <Box key={k} m={0}>{`${ms.valueMap[k]}: ${k}`}</Box>)
                        : null
                      }</Typography>
                    </Grid>,
                    <Grid item key={ms.id+"_vv"} xs={6} sm={6} md={6} lg={6} xl={6}>{
                      ms.tsHistory === null || ms.tsHistory === undefined || ms.tsVector === null || ms.tsVector === undefined
                      ? ""
                      : <FlexibleWidthXYPlot
                          height={65}
                          xType="time"
                          xDomain={chartXDomain}
                          yDomain={[ms.userInput ? ms.userInput.min : Math.min(0, ms.tsHistory.min("data"), ms.tsVector.min("data")), ms.userInput ? ms.userInput.max : Math.max(1, ms.tsHistory.max("data"), ms.tsVector.max("data"))]}
                      >
                          <XAxis tickSizeInner={0} tickFormat={date => (d3.timeHour(date) < date ? moment(date).format("H[h]mm") : d3.timeDay(date) < date ? moment(date).format("H[h]") : (<tspan fontWeight="bolder">{moment(date).format("ddd DD")}</tspan>))} />
                          <HorizontalGridLines />
                          <HorizontalRectSeries
                            data={ms.rectHistory ? ms.rectHistory.map(e => ({x: e.x, x0: e.x0, y: 0, y0: ms.userInput ? ms.userInput.max : Math.max(1, ms.tsHistory.max("data"), ms.tsVector.max("data")), color:e.color})): []}
                            colorType="literal"
                            opacity={0.7}
                          />
                          <LineMarkSeries
                            data={ms.xyHistory}
                            color={COLOR_HISTORY}
                            size={3}
                            getNull={(d) => d.y !== null}
                            onNearestX={(datapoint, {index})=>{
                              setCrosshairValues([datapoint])
                              setSelectedX(datapoint.x)
                            }}
                            onSeriesMouseOut={()=>{setCrosshairValues([]);setSelectedX(null)}}
                          />
                          <LineSeries
                            data={ms.xyVector}
                            color={COLOR_FORECAST}
                            getNull={(d) => d.y !== null}
                            onNearestX={(datapoint, {index})=>{
                              if (index > 0) {
                                setCrosshairValues([datapoint])
                                setSelectedX(datapoint.x)
                              }
                            }}
                            onSeriesMouseOut={()=>{setCrosshairValues([]);setSelectedX(null)}}
                          />
                          <Crosshair values={crosshairValues} style={{line:{backgroundColor: (selectedX && (selectedX > new Date()) ? COLOR_FORECAST : COLOR_HISTORY)}}}>
                            <div width="0px"></div>
                          </Crosshair>
                          <Highlight
                            enableY={false}
                            onBrushEnd={area => setChartXDomain(area ? [area.left, area.right] : [getTimeRange(moment().toDate()).begin(), getFutureTimeRange(moment().toDate()).end()])}
                          />
                        </FlexibleWidthXYPlot>
                    }
                    </Grid>
              ])})
            ))}
          </Grid>
        </Paper>
      </div>
    )
  }
}

const createLogEntry = gql`
mutation createLogEntryMutation($projectID: String!, $name: String!, $description: String!, $start: DateTime!, $end: DateTime!) {
createLogEntry(
    projectID:$projectID
    name:$name
    description:$description
    start:$start
    end:$end
) { id }}`

const updateControlMetaData = gql`
mutation updateControlMetaData($id: ID!, $metaData: Json!) {
  updateControlMetaData(
    id: $id
    metaData: $metaData
  ) { id metaData }
}`;

const updateBuildingControlCache = gql`
query updateBuildingControlCache($projectID: String!) {
  building (where: {projectID: $projectID}) {
        id
        activeControl {
          id
          metaData
        }
    }
}`;

const controlData = gql`
query controlData ( $projectID: String! ) {
    me {
        id
        name
        externalBuildingRoles ( where: { building: { projectID: $projectID } } ) {
            building {
              id
              projectID
              controlStatus
              activeControl {
                id
                controlVector {
                  id
                  payload
                }
                controlHistory {
                  id
                  presentValues
                  referenceValues
                  controlValues
                  lastControlValues
                  pointStates
                }
                metaData
              }
            }
        }
        client {
            id
            displayName
            buildings ( where: { projectID: $projectID } ) {
              id
              projectID
              controlStatus
              activeControl {
                id
                controlVector {
                  id
                  payload
                }
                controlHistory {
                  id
                  presentValues
                  referenceValues
                  controlValues
                  lastControlValues
                  pointStates
                }
                metaData
              }
            }
        }
    }
}`

export default compose(
  graphql(createLogEntry, {name : 'createLogEntryMutation'}),
  graphql(updateControlMetaData, {name : 'updateControlMetaDataMutation'}),
  graphql(controlData, { options: (props) => ({variables:{ projectID :props.projectID}})})
)(withStyles(styles, { withTheme: true })(OperationControlOld))
