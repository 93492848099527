import React, { useState, useEffect } from 'react'

import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import createPalette from '@material-ui/core/styles/createPalette'
import createTypography from '@material-ui/core/styles/createTypography'
import DefaultTheme from '../DefaultTheme';

import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Grid from '@material-ui/core/Grid';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import CircularProgress from '@material-ui/core/CircularProgress';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import SaveIcon from '@material-ui/icons/Save';
import MailIcon from '@material-ui/icons/Mail';
import DeleteIcon from '@material-ui/icons/Delete';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import DoneIcon from '@material-ui/icons/Done';
import RefreshIcon from '@material-ui/icons/Refresh'

import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';

import UploadClientLogo from '../components/UploadClientLogo';
import { CurrentUserContext } from '../contexts/CurrentUser'

import { SketchPicker } from 'react-color';
import { IconButton, InputAdornment } from '@material-ui/core'
import { Redirect } from 'react-router-dom'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ExternalUsersTableRows = ({projectID}) => { 
  const { data, loading, error } = useQuery(gql`
    query getExternalUsersOfBuilding ($projectID: String!) {
      building(where:{projectID:$projectID}) {
        id
        projectID
        externalBuildingRoles {
          id
          role
          user {
            id
            name
            email
            authId
          }
        }
      }
    } 
  `, {
    variables: {
      projectID,
    }
  })

  if (loading) return (
    <TableRow>
      <TableCell colSpan="4">loading...</TableCell>
    </TableRow>
  )

  if (error) return (
    <TableRow>
      <TableCell colSpan="4">ERROR...</TableCell>
    </TableRow>
  )

  if (data.building) return (
    <>
      {data.building.externalBuildingRoles && data.building.externalBuildingRoles
        .sort((a, b) => a.user.name.localeCompare(b.user.name))
        .map((link) => (
        <TableRow key={link.user.id}>
          <TableCell>{link.user.name}</TableCell>
          <TableCell>{link.user.email}</TableCell>
          <TableCell>EXTERNAL - {link.role}</TableCell>
          <TableCell>{link.user.authId?"Active [" + link.user.authId.split("|")[0] + "]":"Created"}</TableCell>
        </TableRow>
      ))}
    </>
  )

  return (
    <TableRow>
      <TableCell colSpan="4">No Data</TableCell>
    </TableRow>
  )
}

const CreateProjectForm = props => {
  const MIN_PASSWORD_LENGTH = 16;

  const [selectedClientId, setSelectedClientId] = useState(null)
  const [projectID, setProjectID] = useState("")
  const [password, setPassword] = useState("")
  const [telegrafInterval, setTelegrafInterval] = useState("10s")
  const [deviceUUID, setDeviceUUID] = useState("")
  
  const [displayName, setDisplayName] = useState("")
  const [description, setDescription] = useState("")

  const [address, setAddress] = useState("")
  const [altitude, setAltitude] = useState("")
  const [latitude, setLatitude] = useState("")
  const [longitude, setLongitude] = useState("")
  const [city, setCity] = useState("")
  const [country, setCountry] = useState("Belgium")
  const [timezone, setTimezone] = useState("")

  const [useCase, setUseCase] = useState("office")
  const [contactName, setContactName] = useState("")
  const [contactEmail, setContactEmail] = useState("")
  const [redirectURL, setRedirectURL] = useState("")

  const [createProject] = useMutation(gql`
    mutation CreateProject(
      $projectID: String!,
      $clientID: ID!,
      $password: String!,
      $telegrafInterval: String!,
      $deviceUUID: String!,
      
      $address: String!,
      $country: String!,
      $city: String!,
      $displayName: String!,
      $description: String!,
      $altitude: Float!,
      $latitude: Float!,
      $longitude: Float!,

      $timezone: String!,
      $useCase: String!,
      $contactName: String!,
      $contactEmail: String!,
      ) {
      createProject(
        projectID: $projectID,
        clientID: $clientID,
        password: $password,
        telegrafInterval: $telegrafInterval,
        deviceUUID: $deviceUUID,
        
        address: $address,
        country: $country,
        city: $city,
        displayName: $displayName,
        description: $description,
        altitude: $altitude,
        latitude: $latitude,
        longitude: $longitude,
        timezone: $timezone,
        useCase: $useCase,
        contactName: $contactName,
        contactEmail: $contactEmail,
      ) {
        id
      }
    }
  `)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const telegrafIntervals = [
    "10s",
    "30s",
    "1m",
    "5m",
  ]

  const countries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Antarctic Territory",
    "British Indian Ocean Territory",
    "British Virgin Islands",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Canton and Enderbury Islands",
    "Cape Verde",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos [Keeling] Islands",
    "Colombia",
    "Comoros",
    "Congo - Brazzaville",
    "Congo - Kinshasa",
    "Cook Islands",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Côte d’Ivoire",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Dronning Maud Land",
    "East Germany",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories",
    "French Southern and Antarctic Territories",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard Island and McDonald Islands",
    "Honduras",
    "Hong Kong SAR China",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Johnston Island",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macau SAR China",
    "Macedonia",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Metropolitan France",
    "Mexico",
    "Micronesia",
    "Midway Islands",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar [Burma]",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "Netherlands Antilles",
    "Neutral Zone",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "North Korea",
    "North Vietnam",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pacific Islands Trust Territory",
    "Pakistan",
    "Palau",
    "Palestinian Territories",
    "Panama",
    "Panama Canal Zone",
    "Papua New Guinea",
    "Paraguay",
    "People's Democratic Republic of Yemen",
    "Peru",
    "Philippines",
    "Pitcairn Islands",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Réunion",
    "Saint Barthélemy",
    "Saint Helena",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Serbia and Montenegro",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and the South Sandwich Islands",
    "South Korea",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Svalbard and Jan Mayen",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syria",
    "São Tomé and Príncipe",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands",
    "Tuvalu",
    "U.S. Minor Outlying Islands",
    "U.S. Miscellaneous Pacific Islands",
    "U.S. Virgin Islands",
    "Uganda",
    "Ukraine",
    "Union of Soviet Socialist Republics",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Unknown or Invalid Region",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City",
    "Venezuela",
    "Vietnam",
    "Wake Island",
    "Wallis and Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe",
    "Åland Islands",
  ]

  const useCases = [
    "office",
    "24/7",
  ]

  const timezones = [
    "Africa/Abidjan",
    "Africa/Accra",
    "Africa/Addis_Ababa",
    "Africa/Algiers",
    "Africa/Asmara",
    "Africa/Bamako",
    "Africa/Bangui",
    "Africa/Banjul",
    "Africa/Bissau",
    "Africa/Blantyre",
    "Africa/Brazzaville",
    "Africa/Bujumbura",
    "Africa/Cairo",
    "Africa/Casablanca",
    "Africa/Ceuta",
    "Africa/Conakry",
    "Africa/Dakar",
    "Africa/Dar_es_Salaam",
    "Africa/Djibouti",
    "Africa/Douala",
    "Africa/El_Aaiun",
    "Africa/Freetown",
    "Africa/Gaborone",
    "Africa/Harare",
    "Africa/Johannesburg",
    "Africa/Juba",
    "Africa/Kampala",
    "Africa/Khartoum",
    "Africa/Kigali",
    "Africa/Kinshasa",
    "Africa/Lagos",
    "Africa/Libreville",
    "Africa/Lome",
    "Africa/Luanda",
    "Africa/Lubumbashi",
    "Africa/Lusaka",
    "Africa/Malabo",
    "Africa/Maputo",
    "Africa/Maseru",
    "Africa/Mbabane",
    "Africa/Mogadishu",
    "Africa/Monrovia",
    "Africa/Nairobi",
    "Africa/Ndjamena",
    "Africa/Niamey",
    "Africa/Nouakchott",
    "Africa/Ouagadougou",
    "Africa/Porto-Novo",
    "Africa/Sao_Tome",
    "Africa/Tripoli",
    "Africa/Tunis",
    "Africa/Windhoek",
    "America/Adak",
    "America/Anchorage",
    "America/Anguilla",
    "America/Antigua",
    "America/Araguaina",
    "America/Argentina/Buenos_Aires",
    "America/Argentina/Catamarca",
    "America/Argentina/Cordoba",
    "America/Argentina/Jujuy",
    "America/Argentina/La_Rioja",
    "America/Argentina/Mendoza",
    "America/Argentina/Rio_Gallegos",
    "America/Argentina/Salta",
    "America/Argentina/San_Juan",
    "America/Argentina/San_Luis",
    "America/Argentina/Tucuman",
    "America/Argentina/Ushuaia",
    "America/Aruba",
    "America/Asuncion",
    "America/Atikokan",
    "America/Bahia",
    "America/Bahia_Banderas",
    "America/Barbados",
    "America/Belem",
    "America/Belize",
    "America/Blanc-Sablon",
    "America/Boa_Vista",
    "America/Bogota",
    "America/Boise",
    "America/Cambridge_Bay",
    "America/Campo_Grande",
    "America/Cancun",
    "America/Caracas",
    "America/Cayenne",
    "America/Cayman",
    "America/Chicago",
    "America/Chihuahua",
    "America/Costa_Rica",
    "America/Creston",
    "America/Cuiaba",
    "America/Curacao",
    "America/Danmarkshavn",
    "America/Dawson",
    "America/Dawson_Creek",
    "America/Denver",
    "America/Detroit",
    "America/Dominica",
    "America/Edmonton",
    "America/Eirunepe",
    "America/El_Salvador",
    "America/Fortaleza",
    "America/Fort_Nelson",
    "America/Glace_Bay",
    "America/Goose_Bay",
    "America/Grand_Turk",
    "America/Grenada",
    "America/Guadeloupe",
    "America/Guatemala",
    "America/Guayaquil",
    "America/Guyana",
    "America/Halifax",
    "America/Havana",
    "America/Hermosillo",
    "America/Indiana/Indianapolis",
    "America/Indiana/Knox",
    "America/Indiana/Marengo",
    "America/Indiana/Petersburg",
    "America/Indiana/Tell_City",
    "America/Indiana/Vevay",
    "America/Indiana/Vincennes",
    "America/Indiana/Winamac",
    "America/Inuvik",
    "America/Iqaluit",
    "America/Jamaica",
    "America/Juneau",
    "America/Kentucky/Louisville",
    "America/Kentucky/Monticello",
    "America/Kralendijk",
    "America/La_Paz",
    "America/Lima",
    "America/Los_Angeles",
    "America/Lower_Princes",
    "America/Maceio",
    "America/Managua",
    "America/Manaus",
    "America/Marigot",
    "America/Martinique",
    "America/Matamoros",
    "America/Mazatlan",
    "America/Menominee",
    "America/Merida",
    "America/Metlakatla",
    "America/Mexico_City",
    "America/Miquelon",
    "America/Moncton",
    "America/Monterrey",
    "America/Montevideo",
    "America/Montserrat",
    "America/Nassau",
    "America/New_York",
    "America/Nipigon",
    "America/Nome",
    "America/Noronha",
    "America/North_Dakota/Beulah",
    "America/North_Dakota/Center",
    "America/North_Dakota/New_Salem",
    "America/Nuuk",
    "America/Ojinaga",
    "America/Panama",
    "America/Pangnirtung",
    "America/Paramaribo",
    "America/Phoenix",
    "America/Port-au-Prince",
    "America/Port_of_Spain",
    "America/Porto_Velho",
    "America/Puerto_Rico",
    "America/Punta_Arenas",
    "America/Rainy_River",
    "America/Rankin_Inlet",
    "America/Recife",
    "America/Regina",
    "America/Resolute",
    "America/Rio_Branco",
    "America/Santarem",
    "America/Santiago",
    "America/Santo_Domingo",
    "America/Sao_Paulo",
    "America/Scoresbysund",
    "America/Sitka",
    "America/St_Barthelemy",
    "America/St_Johns",
    "America/St_Kitts",
    "America/St_Lucia",
    "America/St_Thomas",
    "America/St_Vincent",
    "America/Swift_Current",
    "America/Tegucigalpa",
    "America/Thule",
    "America/Thunder_Bay",
    "America/Tijuana",
    "America/Toronto",
    "America/Tortola",
    "America/Vancouver",
    "America/Whitehorse",
    "America/Winnipeg",
    "America/Yakutat",
    "America/Yellowknife",
    "Antarctica/Casey",
    "Antarctica/Davis",
    "Antarctica/DumontDUrville",
    "Antarctica/Macquarie",
    "Antarctica/Mawson",
    "Antarctica/McMurdo",
    "Antarctica/Palmer",
    "Antarctica/Rothera",
    "Antarctica/Syowa",
    "Antarctica/Troll",
    "Antarctica/Vostok",
    "Arctic/Longyearbyen",
    "Asia/Aden",
    "Asia/Almaty",
    "Asia/Amman",
    "Asia/Anadyr",
    "Asia/Aqtau",
    "Asia/Aqtobe",
    "Asia/Ashgabat",
    "Asia/Atyrau",
    "Asia/Baghdad",
    "Asia/Bahrain",
    "Asia/Baku",
    "Asia/Bangkok",
    "Asia/Barnaul",
    "Asia/Beirut",
    "Asia/Bishkek",
    "Asia/Brunei",
    "Asia/Chita",
    "Asia/Choibalsan",
    "Asia/Colombo",
    "Asia/Damascus",
    "Asia/Dhaka",
    "Asia/Dili",
    "Asia/Dubai",
    "Asia/Dushanbe",
    "Asia/Famagusta",
    "Asia/Gaza",
    "Asia/Hebron",
    "Asia/Ho_Chi_Minh",
    "Asia/Hong_Kong",
    "Asia/Hovd",
    "Asia/Irkutsk",
    "Asia/Jakarta",
    "Asia/Jayapura",
    "Asia/Jerusalem",
    "Asia/Kabul",
    "Asia/Kamchatka",
    "Asia/Karachi",
    "Asia/Kathmandu",
    "Asia/Khandyga",
    "Asia/Kolkata",
    "Asia/Krasnoyarsk",
    "Asia/Kuala_Lumpur",
    "Asia/Kuching",
    "Asia/Kuwait",
    "Asia/Macau",
    "Asia/Magadan",
    "Asia/Makassar",
    "Asia/Manila",
    "Asia/Muscat",
    "Asia/Nicosia",
    "Asia/Novokuznetsk",
    "Asia/Novosibirsk",
    "Asia/Omsk",
    "Asia/Oral",
    "Asia/Phnom_Penh",
    "Asia/Pontianak",
    "Asia/Pyongyang",
    "Asia/Qatar",
    "Asia/Qostanay",
    "Asia/Qyzylorda",
    "Asia/Riyadh",
    "Asia/Sakhalin",
    "Asia/Samarkand",
    "Asia/Seoul",
    "Asia/Shanghai",
    "Asia/Singapore",
    "Asia/Srednekolymsk",
    "Asia/Taipei",
    "Asia/Tashkent",
    "Asia/Tbilisi",
    "Asia/Tehran",
    "Asia/Thimphu",
    "Asia/Tokyo",
    "Asia/Tomsk",
    "Asia/Ulaanbaatar",
    "Asia/Urumqi",
    "Asia/Ust-Nera",
    "Asia/Vientiane",
    "Asia/Vladivostok",
    "Asia/Yakutsk",
    "Asia/Yangon",
    "Asia/Yekaterinburg",
    "Asia/Yerevan",
    "Atlantic/Azores",
    "Atlantic/Bermuda",
    "Atlantic/Canary",
    "Atlantic/Cape_Verde",
    "Atlantic/Faroe",
    "Atlantic/Madeira",
    "Atlantic/Reykjavik",
    "Atlantic/South_Georgia",
    "Atlantic/Stanley",
    "Atlantic/St_Helena",
    "Australia/Adelaide",
    "Australia/Brisbane",
    "Australia/Broken_Hill",
    "Australia/Currie",
    "Australia/Darwin",
    "Australia/Eucla",
    "Australia/Hobart",
    "Australia/Lindeman",
    "Australia/Lord_Howe",
    "Australia/Melbourne",
    "Australia/Perth",
    "Australia/Sydney",
    "Europe/Amsterdam",
    "Europe/Andorra",
    "Europe/Astrakhan",
    "Europe/Athens",
    "Europe/Belgrade",
    "Europe/Berlin",
    "Europe/Bratislava",
    "Europe/Brussels",
    "Europe/Bucharest",
    "Europe/Budapest",
    "Europe/Busingen",
    "Europe/Chisinau",
    "Europe/Copenhagen",
    "Europe/Dublin",
    "Europe/Gibraltar",
    "Europe/Guernsey",
    "Europe/Helsinki",
    "Europe/Isle_of_Man",
    "Europe/Istanbul",
    "Europe/Jersey",
    "Europe/Kaliningrad",
    "Europe/Kiev",
    "Europe/Kirov",
    "Europe/Lisbon",
    "Europe/Ljubljana",
    "Europe/London",
    "Europe/Luxembourg",
    "Europe/Madrid",
    "Europe/Malta",
    "Europe/Mariehamn",
    "Europe/Minsk",
    "Europe/Monaco",
    "Europe/Moscow",
    "Europe/Oslo",
    "Europe/Paris",
    "Europe/Podgorica",
    "Europe/Prague",
    "Europe/Riga",
    "Europe/Rome",
    "Europe/Samara",
    "Europe/San_Marino",
    "Europe/Sarajevo",
    "Europe/Saratov",
    "Europe/Simferopol",
    "Europe/Skopje",
    "Europe/Sofia",
    "Europe/Stockholm",
    "Europe/Tallinn",
    "Europe/Tirane",
    "Europe/Ulyanovsk",
    "Europe/Uzhgorod",
    "Europe/Vaduz",
    "Europe/Vatican",
    "Europe/Vienna",
    "Europe/Vilnius",
    "Europe/Volgograd",
    "Europe/Warsaw",
    "Europe/Zagreb",
    "Europe/Zaporozhye",
    "Europe/Zurich",
    "Indian/Antananarivo",
    "Indian/Chagos",
    "Indian/Christmas",
    "Indian/Cocos",
    "Indian/Comoro",
    "Indian/Kerguelen",
    "Indian/Mahe",
    "Indian/Maldives",
    "Indian/Mauritius",
    "Indian/Mayotte",
    "Indian/Reunion",
    "Pacific/Apia",
    "Pacific/Auckland",
    "Pacific/Bougainville",
    "Pacific/Chatham",
    "Pacific/Chuuk",
    "Pacific/Easter",
    "Pacific/Efate",
    "Pacific/Enderbury",
    "Pacific/Fakaofo",
    "Pacific/Fiji",
    "Pacific/Funafuti",
    "Pacific/Galapagos",
    "Pacific/Gambier",
    "Pacific/Guadalcanal",
    "Pacific/Guam",
    "Pacific/Honolulu",
    "Pacific/Kiritimati",
    "Pacific/Kosrae",
    "Pacific/Kwajalein",
    "Pacific/Majuro",
    "Pacific/Marquesas",
    "Pacific/Midway",
    "Pacific/Nauru",
    "Pacific/Niue",
    "Pacific/Norfolk",
    "Pacific/Noumea",
    "Pacific/Pago_Pago",
    "Pacific/Palau",
    "Pacific/Pitcairn",
    "Pacific/Pohnpei",
    "Pacific/Port_Moresby",
    "Pacific/Rarotonga",
    "Pacific/Saipan",
    "Pacific/Tahiti",
    "Pacific/Tarawa",
    "Pacific/Tongatapu",
    "Pacific/Wake",
    "Pacific/Wallis",
  ]

  const saveProject = async () => {
    setIsSubmitting(true)
    const response = await createProject({
      variables: {
        clientID: selectedClientId,
        projectID,
        password,
        telegrafInterval,
        deviceUUID,
        address,
        country,
        city,
        displayName,
        description,
        altitude,
        latitude,
        longitude,
        timezone,
        useCase,
        contactName,
        contactEmail,
      }
    })
    
    setIsSubmitting(false)
 
    try {
      const id = response.data.createProject.id;
      console.log(`Project ${projectID} with id ${id} created`)
      setRedirectURL(`/buildings/${projectID}/datainput/characteristics`)
      // for example:
      // http://localhost:3000/buildings/ucll_haasrode_03/datainput/characteristics
    } catch {
      // there was an error but we have no details
      // to share about it.
      alert("uknown server error")
    }
  }

  const formIsValid = () => {
    const hasAllFields = (
      selectedClientId &&
      password &&
      telegrafInterval &&
      deviceUUID &&
      projectID &&
      displayName &&
      description &&
      address &&
      city &&
      country &&
      altitude &&
      latitude &&
      longitude &&
      useCase &&
      contactName &&
      contactEmail
      )
    if (!hasAllFields) return false;
    if (password.length < MIN_PASSWORD_LENGTH) {
      return false;
    }
    return true;
  }

  const generatePassword = () => {
    const charSet = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
    let pw = ""
    for (let i = 0; i < MIN_PASSWORD_LENGTH; i++) {
      pw += charSet.charAt(Math.floor(Math.random() * charSet.length))
    }
    setPassword(pw)
  }

  useEffect(() => {
    generatePassword()
  }, [])

  if (redirectURL) {
    return <Redirect to={redirectURL} />
  }

  return (
    <>
      <Grid container spacing={1} xs={12}>
        <Grid container xs={6} md={4}>
          <TextField
            id="select-createProjectClient"
            select
            value={selectedClientId}
            onChange={e => setSelectedClientId(e.target.value)}
            margin="normal"
            style={{width:250}}
            label="Client"
            helperText="Project owner"
            >
              {(props.data.clients ? props.data.clients : []).sort((a,b) => a.displayName.localeCompare(b.displayName)).map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.displayName}
                </MenuItem>
              ))}
          </TextField>
        </Grid>

        <Grid container xs={12}>
          <TextField
            id="select-createProjectProjectID"
            value={projectID}
            onChange={e => setProjectID(e.target.value)}
            margin="normal"
            style={{width:250}}
            label="Project ID"
            />
        </Grid>

        <Grid container xs={12}>
          <TextField
            id="select-createProjectBuildingDisplayName"
            value={displayName}
            onChange={e => setDisplayName(e.target.value)}
            margin="normal"
            style={{width:250}}
            label="Building display name"
            />
        </Grid>

        <Grid container xs={12}>
          <TextField
            id="select-createProjectBuildingDescription"
            value={description}
            onChange={e => setDescription(e.target.value)}
            margin="normal"
            style={{width:250}}
            label="Description"
            helperText="eg Office Space"
            />
        </Grid>

        <Grid container justify="flex-start">
          <Grid item>
            <TextField
              id="select-contactName"
              value={contactName}
              onChange={e => setContactName(e.target.value)}
              margin="normal"
              style={{width:250}}
              label="Contact name"
              />
          </Grid>

          <Grid item>
            <TextField
              id="select-contactEmail"
              value={contactEmail}
              onChange={e => setContactEmail(e.target.value)}
              margin="normal"
              style={{width:250}}
              label="Contact e-mail"
              />
          </Grid>
        </Grid>

        <Grid container xs={12}>
          <TextField
            id="select-createProjectTimezone"
            select
            value={timezone}
            onChange={e => setTimezone(e.target.value)}
            margin="normal"
            style={{width:250}}
            label="Timezone"
            >
              {timezones.map((tz, i) => (
                <MenuItem key={i} value={tz}>{tz}</MenuItem>
              ))}
          </TextField>
        </Grid>

        <Grid container xs={12}>
          <TextField
            id="select-createProjetUseCase"
            select
            value={useCase}
            onChange={e => setUseCase(e.target.value)}
            margin="normal"
            style={{width:250}}
            label="Use case"
            >
              {useCases.map((uc, i) => (
                <MenuItem key={i} value={uc}>{uc}</MenuItem>
              ))}
          </TextField>
        </Grid>

        <Grid container justify="flex-start">
          <Grid item>
            <TextField
              id="select-createProjectAddress"
              value={address}
              onChange={e => setAddress(e.target.value)}
              margin="normal"
              style={{width:250}}
              label="Address"
              helperText=""
              autoComplete="off"
              />
          </Grid>

          <Grid item>
            <TextField
              id="select-createProjectCity"
              value={city}
              onChange={e => setCity(e.target.value)}
              margin="normal"
              style={{width:250}}
              label="City"
              helperText="eg Brussels"
              autoComplete="off"
              />
          </Grid>

          <Grid item>
            <TextField
              id="select-createProjectCountry"
              select
              value={country}
              onChange={e => setCountry(e.target.value)}
              margin="normal"
              style={{width:250}}
              label="Country"
              autoComplete="off"
              >
                {countries.map((country, i) => (
                  <MenuItem key={i} value={country}>{country}</MenuItem>
                ))}
              </TextField>
          </Grid>
        </Grid>

        <Grid container justify="flex-start">
          <Grid item>
            <TextField
              id="select-createProjectLatitude"
              value={latitude}
              onChange={e => setLatitude(e.target.value)}
              margin="normal"
              style={{width:250}}
              label="Latitude"
              helperText="eg 45.09820"
              />
          </Grid>

          <Grid item>
            <TextField
              id="select-createProjectLongitude"
              value={longitude}
              onChange={e => setLongitude(e.target.value)}
              margin="normal"
              style={{width:250}}
              label="Longitude"
              helperText="eg 52.49821"
              />
          </Grid>

          <Grid item>
            <TextField
              id="select-createProjectAltitude"
              value={altitude}
              onChange={e => setAltitude(e.target.value)}
              margin="normal"
              style={{width:250}}
              label="Altitude"
              helperText="in meters"
              />
          </Grid>
        </Grid>

        <Grid container justify="flex-start">
          <Grid item>
            <TextField
              id="text-createProjectPassword"
              value={password}
              onChange={e => setPassword(e.target.value)}
              margin="normal"
              label="InfluxDB Password"
              style={{width:250}}
              helperText={"Minimum "+MIN_PASSWORD_LENGTH+" characters"}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="generate new password"
                      onClick={generatePassword}
                    >
                      <RefreshIcon/>
                    </IconButton>
                  </InputAdornment>
              }}
              />
          </Grid>
          <Grid item>
            <TextField
              id="select-createProjectTelegrafInterval"
              select
              value={telegrafInterval}
              onChange={e => setTelegrafInterval(e.target.value)}
              margin="normal"
              label="Telegraf Interval"
              style={{width:250}}
              helperText="use larger intervals for 3g/4g"
              >
                {telegrafIntervals.map((value, i) => (
                  <MenuItem key={i} value={value}>{value}</MenuItem>            
                ))}
            </TextField>
          </Grid>
          <Grid item>
            <TextField
              id="text-createProjectDeviceUUID"
              value={deviceUUID}
              onChange={e => setDeviceUUID(e.target.value)}
              margin="normal"
              label="Device UUID"
              style={{width:250}}
              helperText="device uuid from balena.io"
              >
            </TextField>
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        {isSubmitting ? <span>Submitting...</span> : (
          <Button onClick={saveProject} variant="contained" disabled={!formIsValid()}>
            Save Project&nbsp;&nbsp;<SaveIcon />
          </Button>
        )}
      </Grid>
    </>
  )
}


class SettingsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      projectID: "",
      clientId: "",
      clientLogoId: "",
      clientLogoUrl: "",
      buildingImageId: "",
      buildingImageUrl: "",
      fontFamily: DefaultTheme.typography.fontFamily,
      primaryColor: DefaultTheme.palette.primary,
      secondaryColor: DefaultTheme.palette.secondary,
      customTheme: DefaultTheme,
      clientAdminNewClientName: "",
      buildingAdminSelectedClientId: "",
      buildingAdminSelectedBuildingIds: [],
      userAdminSelectedClientId: "",
      userAdminSelectedClient: null,
      userAdminNewUserName: "",
      userAdminNewUserEmail: "",
      userAdminNewUserRole: "READER",
      userAdminInviteSent: [],
      newTokenServiceName: "sqb-prisma-kbc",
      newTokenStageName: "prod",
      newTokenExpiresIn: "1d",
      newToken: "",
    }
  }

  componentDidMount() {
    if(!this.props.data.error && !this.props.data.loading && this.props.data.me) {
      this.setState({ projectID: this.props.data.me.selectedBuilding.projectID });
      this.setState({ clientId: this.props.data.me.client.id });
      this.setState({ buildingAdminSelectedClientId: this.props.data.me.client.id });
      if (this.props.data.me.client.buildings && this.props.data.me.client.buildings.length) {
        this.setState({ buildingAdminSelectedBuildingIds: this.props.data.me.client.buildings.map(b => b.id) });
      }
      this.setState({ userAdminSelectedClientId: this.props.data.me.client.id });
      if (this.props.data.me.client.logo && this.props.data.me.client.logo.file) {
        this.setState({ clientLogoId: this.props.data.me.client.logo.id });
        this.setState({ clientLogoUrl: this.props.data.me.client.logo.file.url });
      } else {
        this.setState({ clientLogoId: "" });
        this.setState({ clientLogoUrl: "" });
      };
      if (this.props.data.me.selectedBuilding.image && this.props.data.me.selectedBuilding.image.file) {
        this.setState({ buildingImageId: this.props.data.me.selectedBuilding.image.id });
        this.setState({ buildingImageUrl: this.props.data.me.selectedBuilding.image.file.url });
      } else {
        this.setState({ buildingImageId: "" });
        this.setState({ buildingImageUrl: "" });
      };

      this.setState({ fontFamily: this.props.data.me.client.themeFontFamily });
      this.setState({ primaryColor: this.props.data.me.client.themePrimaryColor });
      this.setState({ secondaryColor: this.props.data.me.client.themeSecondaryColor });
      this.updateStateTheme(
        this.props.data.me.client.themePrimaryColor,
        this.props.data.me.client.themeSecondaryColor,
        this.props.data.me.client.themeFontFamily,
      );
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(!nextProps.data.error && !nextProps.data.loading && nextProps.data.me) {
      if (this.state.buildingAdminSelectedClientId) {
        if (nextProps.data.clients && (nextProps.data.clients.findIndex(c => ((c.id === this.state.buildingAdminSelectedClientId) && c.buildings)) >= 0)) {
          this.setState({ buildingAdminSelectedBuildingIds: nextProps.data.clients.find(c => c.id === this.state.buildingAdminSelectedClientId).buildings.map(b => b.id) });
        } else {
          this.setState({ buildingAdminSelectedBuildingIds: [] });
        }
      } else {
        this.setState({ buildingAdminSelectedClientId: nextProps.data.me.client.id });
        if (nextProps.data.me.client.buildings && nextProps.data.me.client.buildings.length) {
          this.setState({ buildingAdminSelectedBuildingIds: nextProps.data.me.client.buildings.map(b => b.id) });
        }
      }
      if (!this.state.userAdminSelectedClientId) {
        this.setState({ userAdminSelectedClientId: nextProps.data.me.client.id });
      }
      this.setState({ clientId: nextProps.data.me.client.id });
      this.setState({ projectID: nextProps.data.me.selectedBuilding.projectID });
      if (nextProps.data.me.client.logo && nextProps.data.me.client.logo.file) {
        this.setState({ clientLogoId: nextProps.data.me.client.logo.id });
        this.setState({ clientLogoUrl: nextProps.data.me.client.logo.file.url });
      } else {
        this.setState({ clientLogoId: "" });
        this.setState({ clientLogoUrl: "" });
      };
      if (nextProps.data.me.selectedBuilding.image && nextProps.data.me.selectedBuilding.image.file) {
        this.setState({ buildingImageId: nextProps.data.me.selectedBuilding.image.id });
        this.setState({ buildingImageUrl: nextProps.data.me.selectedBuilding.image.file.url });
      } else {
        this.setState({ buildingImageId: "" });
        this.setState({ buildingImageUrl: "" });
      };

      this.setState({ fontFamily: nextProps.data.me.client.themeFontFamily });
      this.setState({ primaryColor: nextProps.data.me.client.themePrimaryColor });
      this.setState({ secondaryColor: nextProps.data.me.client.themeSecondaryColor });
      this.updateStateTheme(
        nextProps.data.me.client.themePrimaryColor,
        nextProps.data.me.client.themeSecondaryColor,
        nextProps.data.me.client.themeFontFamily,
      );
    }
  }

  handleClientAdminNewClientNameChange = event => {
    this.setState({ clientAdminNewClientName: event.target.value })
  }
  handleClientAdminNewClientDisplayNameChange = event => {
    this.setState({ clientAdminNewClientDisplayName: event.target.value })
  }

  handleBuildingAdminClientIdChange = event => {
    this.setState({ buildingAdminSelectedClientId: event.target.value })
    if (this.props.data && !this.props.data.error && !this.props.data.loading && this.props.data.clients) {
      if (this.props.data.clients.findIndex(c => ((c.id === event.target.value) && c.buildings)) >= 0) {
        this.setState({ buildingAdminSelectedBuildingIds: this.props.data.clients.find(c => c.id === event.target.value).buildings.map(b => b.id) });
      } else {
        this.setState({ buildingAdminSelectedBuildingIds: [] });
      }
    }
  }
  handleBuildingAdminSelectedBuildingIdsChange = event => {
    this.setState({ buildingAdminSelectedBuildingIds: event.target.value })
  }

  handleUserAdminClientChange = event => {
    this.setState({ userAdminSelectedClientId: event.target.value })
  }
  handleUserAdminNewUserNameChange = event => {
    this.setState({ userAdminNewUserName: event.target.value })
  }
  handleUserAdminNewUserEmailChange = event => {
    this.setState({ userAdminNewUserEmail: event.target.value })
  }
  handleUserAdminNewUserRoleChange = event => {
    this.setState({ userAdminNewUserRole: event.target.value })
  }

  handleClientAdd = event => {
    this.props.createClientMutation({
      variables: {
        name: this.state.clientAdminNewClientName,
        displayName: this.state.clientAdminNewClientDisplayName,
      },
      refetchQueries: [{
        query: updateClientsCache
      }],
    }).then(({ data }) => {
      console.log('got data', data);
    }).catch((error) => {
      console.log('there was an error sending the query', error);
    });
    this.setState({ clientAdminNewClientName: "" });
    this.setState({ clientAdminNewClientDisplayName: "" });
  }

  handleClientBuildingsUpdate = event => {
    this.props.updateClientBuildingsMutation({
      variables: {
        clientId: this.state.buildingAdminSelectedClientId,
        buildings: this.state.buildingAdminSelectedBuildingIds.map(e => ({id: e})),
      },
      refetchQueries: [{
        query: updateClientsCache
      }],
    }).then(({ data }) => {
      console.log('got data', data);
    }).catch((error) => {
      console.log('there was an error sending the query', error);
    });
  }

  handleUserAdd = event => {
    this.props.createAuth0UserMutation({
      variables: {
        clientId: this.state.userAdminSelectedClientId,
        name: this.state.userAdminNewUserName,
        email: this.state.userAdminNewUserEmail,
        role: this.state.userAdminNewUserRole,
      },
      refetchQueries: [{
        query: updateClientsCache
      }],
    }).then(({ data }) => {
      console.log('got data', data);
    }).catch((error) => {
      console.log('there was an error sending the query', error);
    });
    this.setState({ userAdminNewUserName: "" });
    this.setState({ userAdminNewUserEmail: "" });
    this.setState({ userAdminNewUserRole: "READER" });
  }
  handleSendInvite = (userId) => {
    console.log(`send invite mail to user ${userId}`)
    this.props.sendAuth0PasswordResetMutation({
      variables: { userId },
      refetchQueries: [{
        query: updateClientsCache
      }],
    }).then(({ data }) => {
      console.log(data)
      console.log(this.state.userAdminInviteSent)
      console.log(this.state.userAdminInviteSent.concat(data.sendAuth0PasswordReset.id))
      this.setState({userAdminInviteSent: this.state.userAdminInviteSent.concat(data.sendAuth0PasswordReset.id)})
      console.log('got data', data);
    }).catch((error) => {
      console.log('there was an error sending the query', error);
    });
  }
  handleUserDelete = (userId) => {
    this.props.deleteAuth0UserMutation({
      variables: { userId },
      refetchQueries: [{
        query: updateClientsCache
      }],
    }).then(({ data }) => {
      console.log('got data', data);
    }).catch((error) => {
      console.log('there was an error sending the query', error);
    });
  }

  handleNewTokenServiceNameChange = event => {
    this.setState({ newTokenServiceName: event.target.value })
  }
  handleNewTokenStageNameChange = event => {
    this.setState({ newTokenStageName: event.target.value })
  }
  handleNewTokenExpiresInChange = event => {
    this.setState({ newTokenExpiresIn: event.target.value })
  }
  handleNewToken = () => {
    this.props.getBackendTokenMutation({
      variables: {
        serviceName: this.state.newTokenServiceName,
        stageName: this.state.newTokenStageName,
        expiresIn: this.state.newTokenExpiresIn,
      },
    }).then(({data}) => {
      console.log('got data', data);
      this.setState({ newToken: data.getBackendToken });
    }).catch((error) => {
      console.log('there was an error sending the query', error);
    });
  }

  handleBuildingImageAdd = (uploadedFileId, uploadedFileName) => {
    this.props.createBuildingImageMutation({
      variables: {
        projectID: this.state.projectID,
        fileId: uploadedFileId,
      },
      refetchQueries: [{ query: updateUserCache }],
    }).then(({ data }) => {
      console.log('got data', data);
    }).catch((error) => {
      console.log('there was an error sending the query', error);
    });
  }

  handleBuildingImageDelete = (buildingImageId) => {
    this.props.deleteBuildingImageMutation({
      variables: {
        buildingImageId: this.state.buildingImageId,
      },
      refetchQueries: [{ query: updateUserCache }],
    }).then(({ data }) => {
      console.log('got data', data);
    }).catch((error) => {
      console.log('there was an error sending the query', error);
    });
  }

  handleClientLogoAdd = (uploadedFileId, uploadedFileName) => {
    this.props.createClientLogoMutation({
      variables: {
        clientId: this.state.clientId,
        fileId: uploadedFileId,
      },
      refetchQueries: [{ query: updateUserCache }],
    }).then(({ data }) => {
      console.log('got data', data);
    }).catch((error) => {
      console.log('there was an error sending the query', error);
    });
  }

  handleClientLogoDelete = (clientLogoId) => {
    this.props.deleteClientLogoMutation({
      variables: {
        clientLogoId: this.state.clientLogoId,
      },
      refetchQueries: [{ query: updateUserCache }],
    }).then(({ data }) => {
      console.log('got data', data);
    }).catch((error) => {
      console.log('there was an error sending the query', error);
    });
  }

  updateStateTheme = (primaryColor, secondaryColor, fontFamily) => {
    const palette = createPalette({
      type: 'light',
      primary: { main: primaryColor },
      secondary: { main: secondaryColor },
    });
    this.setState({customTheme: createMuiTheme({
      palette: palette,
      typography: createTypography(palette, { fontFamily: fontFamily }),
    })});
  }

  handleFontFamilyChange = (event) => {
    this.setState({ fontFamily: event.target.value });
    this.updateStateTheme(this.state.primaryColor, this.state.secondaryColor, event.target.value);
  };

  handlePrimaryColorChangeComplete = (color) => {
    this.setState({ primaryColor: color.hex });
    this.updateStateTheme(color.hex, this.state.secondaryColor, this.state.fontFamily);
  };

  handleSecondaryColorChangeComplete = (color) => {
    this.setState({ secondaryColor: color.hex });
    this.updateStateTheme(this.state.primaryColor, color.hex, this.state.fontFamily);
  };

  handleThemeSave = () => {
    this.props.updateClientMutation({
      variables: {
        clientId: this.state.clientId,
        primaryColor: this.state.primaryColor,
        secondaryColor: this.state.secondaryColor,
        fontFamily: this.state.fontFamily,
      },
      refetchQueries: [{ query: updateUserCache }],
    }).then(({ data }) => {
      console.log('got data', data);
    }).catch((error) => {
      console.log('there was an error sending the query', error);
    });
  }

  render() {
    return (
      <CurrentUserContext.Consumer>{ me => { return (
      <div>
        <div>
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Current User</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container spacing={4}>
                {this.props.data.error ? (
                  <Grid item xs={12}>
                    <Typography gutterBottom>Error fetching user data!</Typography>
                  </Grid>
                ) : this.props.data.loading ? (
                  <Grid item xs={12}>
                    <CircularProgress size={50} color="secondary" />
                  </Grid>
                ) : !this.props.data.me ? (
                  <Grid item xs={12}>
                    <Typography>Loading user Data...</Typography>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <Typography>{this.props.data.me.name} [client: {this.props.data.me.client.displayName} - role: {me.role} - email:{this.props.data.me.email}]</Typography>
                  </Grid>
                )}
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel hidden={true}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Building</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container spacing={4}>
                {this.props.data.error ? (
                    <Grid item xs={12}>
                        <Typography gutterBottom>Error fetching building data!</Typography>
                    </Grid>
                ) : this.props.data.loading ? (
                    <Grid item xs={12}>
                        <CircularProgress size={50} color="secondary" />
                    </Grid>
                ) : (
                  this.state.buildingImageUrl ? (
                    <div>
                      <img alt="Building Illustration" src={this.state.buildingImageUrl} style={{backgroundColor:"#999999", maxWidth:"500px", maxHeight:"500px"}} />
                      <br /><br />
                      <Button onClick={this.handleBuildingImageDelete}>
                        Delete Image&nbsp;&nbsp;
                        <DeleteIcon />
                      </Button>
                    </div>
                  ) : (
                    <Grid item>
                      <Typography>
                        Upload a building image for {this.state.projectID}
                      </Typography>
                      <Typography>
                        &nbsp;
                      </Typography>
                    </Grid>
                  )
                )}
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          {(this.props.data.error || this.props.data.loading || !this.props.data.me ) ? (
            <ExpansionPanel hidden={true}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Client Users</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>Client Users</Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ) : (
            <ExpansionPanel hidden={!(me.role === "OPERATOR" || me.role === "ADMIN" || me.role === "SUPERADMIN")}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Client Users</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Role</TableCell>
                          <TableCell>Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.props.data.me.client.users
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map(user => (
                          <TableRow key={user.id}>
                            <TableCell>{user.name}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>{user.role}</TableCell>
                            <TableCell>{user.authId?"Active [" + user.authId.split("|")[0] + "]":"Created"}</TableCell>
                          </TableRow>
                        ))}
                        <ExternalUsersTableRows projectID={this.state.projectID} />
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )}
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Client Dashboard</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              {this.props.data.error ? (
                <Grid container>
                  <Grid item xs={12}>
                    <Typography gutterBottom>Error fetching dashboard data!</Typography>
                  </Grid>
                </Grid>
              ) : this.props.data.loading ? (
                <Grid container>
                  <Grid item xs={12}>
                    <CircularProgress size={50} color="secondary" />
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={4}>
                  <Grid item>
                    {this.state.clientLogoUrl ? (
                      <div>
                        <img alt="Company Logo" src={this.state.clientLogoUrl} style={{backgroundColor:"#999999", maxWidth:"300px", maxHeight:"300px"}} />
                        <br /><br />
                        <Button variant="contained" onClick={this.handleClientLogoDelete}>
                          Delete Logo&nbsp;&nbsp;
                          <DeleteIcon />
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <Typography color="primary">
                          Upload a logo for {this.state.clientId}
                        </Typography>
                        <Typography>
                          &nbsp;
                        </Typography>
                        <UploadClientLogo clientId={this.state.clientId} />
                      </div>
                    )}
                  </Grid>
                  <Grid item>
                    <div style={{fontFamily: this.state.fontFamily, backgroundColor: this.state.primaryColor, padding: "5px"}}>
                      <Typography>
                        Primary color
                      </Typography>
                      <Typography>
                        &nbsp;
                      </Typography>
                      <SketchPicker
                        color={ this.state.primaryColor }
                        onChangeComplete={ this.handlePrimaryColorChangeComplete }
                      />
                    </div>
                  </Grid>
                  <Grid item>
                    <div style={{fontFamily: this.state.fontFamily, backgroundColor: this.state.secondaryColor, padding: "5px"}}>
                      <Typography>
                        Secondary color
                      </Typography>
                      <Typography>
                        &nbsp;
                      </Typography>
                      <SketchPicker
                        color={ this.state.secondaryColor }
                        onChangeComplete={ this.handleSecondaryColorChangeComplete }
                      />
                    </div>
                  </Grid>
                  <Grid item>
                    <InputLabel htmlFor="font" color="primary">Font</InputLabel><br/>
                    <Select
                      value={this.state.fontFamily}
                      onChange={this.handleFontFamilyChange}
                      input={<Input name="fontFamily" id="fontFamily" />}
                    >
                      <MenuItem value='montserrat'>Montserrat</MenuItem>
                      <MenuItem value='roboto'>Roboto</MenuItem>
                      <MenuItem value='lato'>Lato</MenuItem>
                      <MenuItem value='verdana'>Verdana</MenuItem>
                    </Select>
                    <br/>
                    <br/>
                    <br/>
                    <MuiThemeProvider theme={this.state.customTheme}>
                      <div>
                        <Typography variant="h6">Example text</Typography><br/>
                        <Typography color="primary">Primary color text</Typography><br/>
                        <Typography color="secondary">Secondary color text</Typography><br/>
                      </div>
                    </MuiThemeProvider>
                    <br/>
                    <Button variant="contained" onClick={this.handleThemeSave}>
                      Save Theme&nbsp;&nbsp;
                      <SaveIcon />
                    </Button>
                  </Grid>
                </Grid>
              )}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
        <div><br/></div>
        {!(this.props.data.error || this.props.data.loading || !me) && (
          <div>
            <ExpansionPanel hidden={!(me.role === "SUPERADMIN")}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Client Administration</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid container spacing={4}>
                  {!(this.props.data.error || this.props.data.loading)
                  && this.props.data.clients && (
                    <Grid item xs={12}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Name (short &amp; lowercase - prefix for ProjectID)</TableCell>
                            <TableCell>Display Name</TableCell>
                            <TableCell>&nbsp;</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow key="add">
                            <TableCell>
                              <TextField
                                id="select-newClientName"
                                value={this.state.clientAdminNewClientName}
                                margin="normal"
                                onChange={this.handleClientAdminNewClientNameChange}
                              >
                              </TextField>
                            </TableCell>
                            <TableCell>
                              <TextField
                                id="select-newClientDisplayName"
                                value={this.state.clientAdminNewClientDisplayName}
                                margin="normal"
                                onChange={this.handleClientAdminNewClientDisplayNameChange}
                              >
                              </TextField>
                            </TableCell>
                            <TableCell>
                              <Button variant="contained" style={{marginLeft:10}} aria-label="AddClient" onClick={this.handleClientAdd}>
                                Create New Client
                                <SaveIcon style={{marginLeft:10}} />
                              </Button>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                  )}
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel hidden={!(me.role === "SUPERADMIN")}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Create New Project</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <CreateProjectForm {...this.props}></CreateProjectForm>
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel hidden={!(me.role === "SUPERADMIN")}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Building Administration</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid container spacing={4}>
                  {!(this.props.data.error || this.props.data.loading)
                  && this.props.data.clients && this.state.buildingAdminSelectedBuildingIds && (
                    <Grid item xs={12}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Client</TableCell>
                            <TableCell>Buildings</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <TextField
                                id="select-buildingAdminClient"
                                select
                                value={this.state.buildingAdminSelectedClientId}
                                onChange={this.handleBuildingAdminClientIdChange}
                                margin="normal"
                                style={{width: 250}}
                              >
                                {this.props.data.clients.sort((a,b) => a.displayName.localeCompare(b.displayName)).map(option => (
                                  <MenuItem key={option.id} value={option.id}>
                                    {option.displayName + ((option.buildings && option.buildings.length) ? ' (' + option.buildings.length + ')' : ' (-none-)')}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </TableCell>
                            <TableCell>
                              <Select
                                multiple
                                value={this.state.buildingAdminSelectedBuildingIds}
                                onChange={this.handleBuildingAdminSelectedBuildingIdsChange}
                                input={<Input id="select-buildingAdminClientBuildings" />}
                                renderValue={selected => selected.length > 5
                                  ? '< ' + selected.length + ' buildings selected >'
                                  : this.props.data.buildings.filter(building => selected.indexOf(building.id)>-1).sort((a,b) => a.displayName.localeCompare(b.displayName)).map(building=>building.displayName).join(', ')}
                                MenuProps={MenuProps}
                                style={{width: 600}}
                              >
                                {this.props.data.buildings.sort((a,b) => a.displayName.localeCompare(b.displayName)).map(building => (
                                  <MenuItem key={building.id} value={building.id}>
                                    <Checkbox checked={this.state.buildingAdminSelectedBuildingIds.indexOf(building.id)>-1} />
                                    <ListItemText primary={building.displayName} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </TableCell>
                            <TableCell>
                              <Button variant="contained" style={{marginLeft:10}} aria-label="UpdateClientBuildings" onClick={this.handleClientBuildingsUpdate}>
                                Update Client Buildings
                                <SaveIcon style={{marginLeft:10}} />
                              </Button>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                  )}
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel hidden={!(me.role === "SUPERADMIN")}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>User Administration</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                {!(this.props.data.error || this.props.data.loading)
                && this.props.data.clients && this.state.userAdminSelectedClientId && (
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <TextField
                        id="select-userAdminClient"
                        select
                        value={this.state.userAdminSelectedClientId}
                        onChange={this.handleUserAdminClientChange}
                        margin="normal"
                        style={{width: 250}}
                      >
                        {this.props.data.clients.sort((a,b) => a.displayName.localeCompare(b.displayName)).map(option => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.displayName}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Role</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.props.data.clients.filter(c => c.id === this.state.userAdminSelectedClientId)[0] 
                          .users.sort((a, b) => a.name.localeCompare(b.name))
                          .map(user => (
                            <TableRow key={user.id}>
                              <TableCell>{user.name}</TableCell>
                              <TableCell>{user.email}</TableCell>
                              <TableCell>{user.role}</TableCell>
                              <TableCell>{user.authId?"Active [" + user.authId.split("|")[0] + "]":"Created"}</TableCell>
                              <TableCell>
                                <Button variant="contained" style={{marginLeft:10}} aria-label="SendInviteMail" onClick={() => this.handleSendInvite(user.id)}>
                                  Send Invite {
                                    this.state.userAdminInviteSent.includes(user.id)
                                    ? <DoneIcon style={{marginLeft:10}} />
                                    : <MailIcon style={{marginLeft:10}} />
                                  }
                                </Button>
                                <Button variant="contained" style={{marginLeft:10}} aria-label="DeleteUser" onClick={() => this.handleUserDelete(user.id)}>
                                  Delete
                                  <DeleteIcon style={{marginLeft:10}} />
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                          <TableRow key="add">
                            <TableCell>
                              <TextField
                                id="select-newUserName"
                                value={this.state.userAdminNewUserName}
                                margin="normal"
                                onChange={this.handleUserAdminNewUserNameChange}
                              >
                              </TextField>
                            </TableCell>
                            <TableCell>
                              <TextField
                                id="select-newUserEmail"
                                value={this.state.userAdminNewUserEmail}
                                margin="normal"
                                onChange={this.handleUserAdminNewUserEmailChange}
                              >
                              </TextField>
                            </TableCell>
                            <TableCell>
                              <TextField
                                id="select-newUserRole"
                                select
                                value={this.state.userAdminNewUserRole}
                                margin="normal"
                                onChange={this.handleUserAdminNewUserRoleChange}
                              >
                                  <MenuItem key="READER" value="READER">Read-only User</MenuItem>
                                  <MenuItem key="OPERATOR" value="OPERATOR">Operator</MenuItem>
                                  <MenuItem key="ADMIN" value="ADMIN">Admin</MenuItem>
                                  <MenuItem key="SUPERADMIN" value="SUPERADMIN"  hidden={!(me.role === "SUPERADMIN")}>Super Admin</MenuItem>
                              </TextField>
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell>
                              <Button variant="contained" style={{marginLeft:10}} aria-label="AddUser" onClick={this.handleUserAdd}>
                                Create New User
                                <SaveIcon style={{marginLeft:10}} />
                              </Button>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                  </Grid>
                )}
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel hidden={!(me.role === "SUPERADMIN")}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Backend API Token</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <TextField
                              id="select-newTokenServiceName"
                              select
                              value={this.state.newTokenServiceName}
                              margin="normal"
                              onChange={this.handleNewTokenServiceNameChange}
                            >
                              <MenuItem key="sqb-prisma" value="sqb-prisma">sqb-prisma</MenuItem>
                              <MenuItem key="sqb-prisma-kbc" value="sqb-prisma-kbc">sqb-prisma-kbc</MenuItem>
                            </TextField>
                          </TableCell>
                          <TableCell>
                            <TextField
                              id="select-newTokenStageName"
                              select
                              value={this.state.newTokenStageName}
                              margin="normal"
                              onChange={this.handleNewTokenStageNameChange}
                            >
                              <MenuItem key="dev" value="dev">dev</MenuItem>
                              <MenuItem key="prod" value="prod">prod</MenuItem>
                            </TextField>
                          </TableCell>
                          <TableCell>
                            <TextField
                              id="select-newTokenExpiresIn"
                              value={this.state.newTokenExpiresIn}
                              margin="normal"
                              onChange={this.handleNewTokenExpiresInChange}
                            >
                            </TextField>
                          </TableCell>
                          <TableCell>
                            <Button variant="contained" style={{marginLeft:10}} aria-label="GetToken" onClick={this.handleNewToken}>
                              Get token
                              <VpnKeyIcon style={{marginLeft:10}} />
                            </Button>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={4}>
                            <TextField
                              id="select-newToken"
                              value={this.state.newToken}
                              margin="normal"
                              fullWidth={true}
                              multiline={true}
                              rows={2}
                              rowsMax={5}
                            >
                            </TextField>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        )}
      </div>
      )}}
      </CurrentUserContext.Consumer>
    )
  }
}

const createAuth0User = gql`
mutation createAuth0User($clientId: ID!, $name: String!, $email: String!, $role: String!) {
  createAuth0User(data: {client: {connect: {id: $clientId}}, name: $name, email: $email, role: $role}) {
    id
  }
}`;

const sendAuth0PasswordReset = gql`
mutation sendAuth0PasswordReset($userId: ID!) {
  sendAuth0PasswordReset(where: {id: $userId}) {
    id
  }
}`;

const deleteAuth0User = gql`
mutation deleteAuth0User($userId: ID!) {
  deleteAuth0User(where: {id: $userId}) {
    id
  }
}`;

const getBackendToken = gql`
mutation getBackendToken($serviceName: String!, $stageName: String!, $expiresIn: String!) {
  getBackendToken(serviceName:$serviceName, stageName:$stageName, expiresIn:$expiresIn)
}`;

const createBuildingImage = gql`
mutation createBuildingImage($projectID: String!, $fileId: ID!) {
  createBuildingImage(data: {projectID: $projectID, fileId: $fileId}) {
    id
  }
}`;

const deleteBuildingImage = gql`
mutation deleteBuildingImage($buildingImageId: ID!) {
  deleteBuildingImage(where: {id:$buildingImageId}) {
    id
  }
}`;

const createClient = gql`
mutation createClient($name: String!, $displayName: String!) {
  createClient(
    name: $name
    displayName: $displayName
  ) { id }
}`;

const uploadClientLogo = gql`
mutation uploadClientLogo($clientId: ID!, $file: Upload!) {
  uploadClientLogo(clientId: $clientId, file: $file) {
    id
  }
}`;

const createClientLogo = gql`
mutation createClientLogo($clientId: ID!, $fileId: ID!) {
  createClientLogo(data: {clientId:$clientId, fileId: $fileId}) {
    id
  }
}`;

const deleteClientLogo = gql`
mutation deleteClientLogo($clientLogoId: ID!) {
  deleteClientLogo(id:$clientLogoId) {
    id
  }
}`;

const updateClientBuildings = gql`
mutation updateClientBuildings($clientId: ID!, $buildings: [BuildingWhereUniqueInput!]) {
  updateClientBuildings(
    id: $clientId
    buildings: $buildings
  ) { id }
}`;

const updateClient = gql`
mutation updateClient($clientId: ID!, $primaryColor: String, $secondaryColor: String, $fontFamily: String) {
  updateClient(
    id: $clientId
    themePrimaryColor: $primaryColor
    themeSecondaryColor: $secondaryColor
    themeFontFamily: $fontFamily
  ) {
    id
  }
}`;

const updateClientsCache = gql`
query updateClientsCache {
  clients {
    id
    name
    displayName
    users {
      id
      name
      email
      role
      authId
    }
    buildings {
      id
      displayName
      projectID
    }
  }
}
`

const updateUserCache = gql`
query updateUserCache {
  me {
    id
    email
    name
    selectedBuilding {
      id
      projectID
      displayName
      lastDataPushDateTime
      image {
        id
        file {
          id
          url
        }
      }
    }
    client {
      id
      displayName
      themeFontFamily
      themePrimaryColor
      themeSecondaryColor
      logo {
        id
        file {
          id
          url
        }
      }
      buildings {
        id
        projectID
        displayName
        description
        controlStatus
        controlStatusSetpoint
        controlEventHistory {
          id
          payload
        }
        comfortSettingThermal
        comfortSettingAirquality
        lastDataPushDateTime
        location {
          id
          city
        }
        image {
          id
          file {
            id
            url
          }
        }
      }
      users {
        id
        name
        email
        role
        authId
      }
    }
  }
}`;

const getAllUsersAndMe = gql`
query getAllUsersAndMe {
  clients {
    id
    displayName
    users {
      id
      name
      email
      role
      authId
    }
    buildings {
      id
      displayName
      projectID
    }
  }
  buildings {
    id
    projectID
    displayName
  }
  me {
    id
    email
    name
    role
    selectedBuilding {
      id
      projectID
      displayName
      lastDataPushDateTime
      image {
        id
        file {
          id
          url
        }
      }
    }
    client {
      id
      displayName
      themeFontFamily
      themePrimaryColor
      themeSecondaryColor
      logo {
        id
        file {
          id
          url
        }
      }
      buildings {
        id
        projectID
        displayName
        description
        controlStatus
        controlStatusSetpoint
        controlEventHistory {
          id
          payload
        }
        comfortSettingThermal
        comfortSettingAirquality
        lastDataPushDateTime
        location {
          id
          city
        }
        image {
          id
          file {
            id
            url
          }
        }
      }
      users {
        id
        name
        email
        role
        authId
      }
    }
  }
}`

export default compose(
  graphql(getAllUsersAndMe),
  graphql(createClient, { name: 'createClientMutation' }),
  graphql(updateClientBuildings, { name: 'updateClientBuildingsMutation' }),
  graphql(createAuth0User, { name: 'createAuth0UserMutation' }),
  graphql(sendAuth0PasswordReset, { name: 'sendAuth0PasswordResetMutation' }),
  graphql(deleteAuth0User, { name: 'deleteAuth0UserMutation' }),
  graphql(getBackendToken, { name: 'getBackendTokenMutation' }),
  graphql(createBuildingImage, { name: 'createBuildingImageMutation' }),
  graphql(deleteBuildingImage, { name: 'deleteBuildingImageMutation' }),
  graphql(uploadClientLogo, { name: 'uploadClientLogoMutation' }),
  graphql(createClientLogo, { name: 'createClientLogoMutation' }),
  graphql(deleteClientLogo, { name: 'deleteClientLogoMutation' }),
  graphql(updateClient, { name: 'updateClientMutation' }),
)(SettingsPage);
